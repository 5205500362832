// core
import React from 'react'

// libraries
import classnames from 'classnames'

// components
import { Card, Button, CardContent, Heading } from 'components';

// styles
import css from '../Dashboard.module.scss'

import Starter from 'assets/images/licenses/starter.png'
import Standard from 'assets/images/licenses/standard.png'
import Professional from 'assets/images/licenses/professional.png'
import { ICompany } from '../../../../api/interfaces/ICompany';

export interface ILicenseWidget {
  /**
   * standard version
   */
  company?: ICompany
}

export const LicenseWidget = ({ company }: ILicenseWidget) => {
  const openContactPage = () => {
    window.open('https://www.webswing.org/contact?license=1', '_blank');
  }

  const images: {
    [key: string]: string
  } = {
    starter: Starter,
    standard: Standard,
    professional: Professional,
  }

  const license = company?.support_ticket_level_id ? company.support_ticket_level : null

  const listUnits = [
      {
          value: 1,
          label: '0-1',
      },
      {
          value: 10,
          label: '2-10',
      },
      {
          value: 100,
          label: '11-100',
      },
      {
          value: 999,
          label: 'Unlimited',
      },
  ]

  const companyUnits = listUnits.find((u)=>u.value === company?.units)

  return (
    <Card title="License" classes={{content: css.cards}}>
      <CardContent className={classnames({ [css.standard]: !!license })}>
        <div className={css.licenseContent}>
          <div>
            <div className={css.webswing}>Webswing</div>
            <Heading
              classes={{ heading: css.licenseHeading }}
              title={license ? (company?.support_ticket_level?.name || 'public') : 'public'}
            />
          </div>
          <div>
            <div className={css.company}>
              Units: <strong>{companyUnits ? companyUnits.label : typeof company?.units === 'number'? String(company?.units): 'N/A' }</strong>
            </div>
            <div className={css.company}>
                Please see your Agreement for more details.
            </div>
              <div className={css.company}>
              Company: <strong>{company?.name || 'N/A'}</strong>
            </div>
            <div className={css.company}>
              Renewal: <strong>{license && company?.license_renewal_at ? company?.license_renewal_at : 'N/A' }</strong>
            </div>
            {!license && (
              <div className={css.contactUs}>
                Want to become a client?{' '}
                <Button className={css.licenseButton} label="Contact us" size="small" onClick={openContactPage} />
              </div>
            )}
          </div>
        </div>
        {license && images.hasOwnProperty(license.name) && <img src={images[license.name]} alt="standardImage" />}
      </CardContent>
    </Card>
  )
}
