// core
import React, { memo } from 'react'

// libraries
import { useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { t } from 'i18n'

// components
import { Backdrop } from 'components/basic/Backdrop/Backdrop'
import { Icon } from 'components'

// partials
import { Logo } from './Partials/Logo'
import { MenuItem } from './Partials/MenuItem'

// styles
import css from './MainMenu.module.scss'

// data (will be fetched from somewhere later)
import { MENU_ITEMS } from './data'
import { AuthService } from '../../../services/AuthService'
import Footer from '../../../pages/auth/Partials/Footer'
import { CompanyLogo } from './Partials/CompanyLogo'

export interface IMainMenuProps {
  /**
   * should menu be shown
   */
  showMenu: boolean
  /**
   * handleToggleShow
   */
  handleMenuClick: (show: boolean) => void
}

export const MainMenu = memo(function MainMenu({ showMenu, handleMenuClick }: IMainMenuProps) {
  const { pathname } = useLocation()

  return (
    <>
      <Backdrop visible={showMenu} zIndex={150} onClick={() => handleMenuClick(false)} />
      <aside className={classnames(css.root, { [css.show]: showMenu })}>
        <div className={css.logo}>
          <Logo />
          <Icon
            size="large"
            color="white"
            className={css.menuCloseIcon}
            name="close"
            onClick={() => handleMenuClick(false)}
          />
        </div>
        <div className={css.menuFlex}>
          <ul className={css.menuItems}>
            {MENU_ITEMS.filter(
              item => null === item.permission || AuthService.hasPermission(item.permission, 'view')
            ).map(item => (
              <MenuItem
                key={item.path}
                activePath={pathname}
                icon={item.icon}
                path={item.path}
                onClick={() => handleMenuClick(false)}
                subItems={item.subItems}>
                {item.name}
              </MenuItem>
            ))}
            <MenuItem
              key="Migrationdemo"
              activePath={pathname}
              icon="miscellaneous_services"
              target="_blank"
              path="https://prototype.webswing.org/migration-examples/"
              onClick={() => handleMenuClick(false)}>
              {t.MIGRATE}
            </MenuItem>
            <MenuItem
              key="documentation"
              activePath={pathname}
              icon="import_contacts"
              target="_blank"
              path="https://www.webswing.org/docs"
              onClick={() => handleMenuClick(false)}>
              {t.DOCUMENTATION}
            </MenuItem>
          </ul>
          <CompanyLogo />
        </div>
        <div className={css.developed}>
          Developed by <a href="https://www.webswing.org">webswing.org</a>
          <Footer />
        </div>
      </aside>
    </>
  )
})
