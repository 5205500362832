// endpoints - USE ABSOLUTE PATHS ONLY !
import { AuthentificationEndpoint } from './endpoints/Authentification'
import { DashboardEndpoint } from './endpoints/Dashboard'
import { IssuesEndpoint } from './endpoints/Issues'
import { DownloadOldEndpoint } from './endpoints/DownloadsOld'
import { SettingsEndpoint } from './endpoints/Settings'
import { OwenEndpoint } from './endpoints/Owen'
import { DownloadsEndpoint } from './endpoints/Downloads'

/** `! README !`
 *
 * This is a global unified class containing all endpoints with their API calls.
 *
 * All endpoints classes must have their instances created here to be used.
 *
 * Goal of this class is to have a unified single import containing all endpoints with all their API calls
 * accessible via dot notation. This also prevents any future issues with barrel imports and circular dependencies.
 *
 * While this class is exported as `default`, KEEP ITS IMPORT CONSISTENT ! Folow the example below:
 * @example
 * // API
 * import API from 'api'
 */
class API {
  AuthEndpoint = new AuthentificationEndpoint()
  DashboardEndpoint = new DashboardEndpoint()
  DownloadEndpoint = new DownloadOldEndpoint()
  Downloadv2Endpoint = new DownloadsEndpoint()
  IssuesEndpoint = new IssuesEndpoint()
  SettingsEndpoint = new SettingsEndpoint()
  OwenEndpoint = new OwenEndpoint()
}

export default new API()
