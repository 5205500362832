// API
import { CallAPI } from '../APIUtils'
// utils
import {
  IForgottenPasswordForm,
  ILoginForm,
  IRegistrationForm,
} from 'utils/forms/AuthentificationForms'

export class AuthentificationEndpoint {
  async DeleteAccount() {
    return CallAPI({ method: 'POST', URL: '/delete-account' })
  }

  async ForgottenPassword(body: IForgottenPasswordForm) {
    return CallAPI({ method: 'POST', URL: '/forgotten-password', body })
  }

  async ResetPassword(body: IForgottenPasswordForm) {
    return CallAPI({ method: 'POST', URL: '/reset-password', body })
  }

  async Login(body: ILoginForm) {
    return CallAPI({ method: 'POST', URL: '/login', body })
  }

  async Logout() {
    return CallAPI({ method: 'POST', URL: '/logout', bAuthentificate: true })
  }

  async Cookie() {
    return CallAPI({ method: 'GET', URL: '/cookie' })
  }

  async Register(body: IRegistrationForm) {
    return CallAPI({ method: 'POST', URL: '/register', body })
  }

  async Recaptcha(body: {token: string}) {
    return CallAPI({ method: 'POST', URL: '/valid-register', body })
  }

  async SocialLoginUrl(driver: string) {
    return CallAPI({ method: 'GET', URL: `/login/${driver}/url`})
  }

  async SocialLogin(driver: string) {
    return CallAPI({ method: 'POST', URL: `/login/${driver}${window.location.search}`})
  }

  async User() {
    return CallAPI({ method: 'GET', URL: `/user`, bAuthentificate: true})
  }

  async CheckInvite(invitation: string) {
    return CallAPI({ method: 'POST', URL: `/check/invite`, body: { invitation }})
  }
}
