// API
import { CallAPI } from '../APIUtils'

export class OwenEndpoint {
  async Answer(key: string, answer: string | string[]) {
    return CallAPI({
      method: 'POST',
      URL: `/user/owen`,
      body: { key, answer },
      bAuthentificate: true,
    })
  }

  async Shown() {
    return CallAPI({
      method: 'POST',
      URL: `/user/owen/shown`,
      bAuthentificate: true,
    })
  }

  async Opened() {
    return CallAPI({
      method: 'POST',
      URL: `/user/owen/opened`,
      bAuthentificate: true,
    })
  }
}
