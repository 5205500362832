// core
import React, { useState, useCallback } from 'react';

// libraries
import classnames from 'classnames';

// components
import { Avatar, Badge, Icon, Divider } from 'components/basic';
import { Popover } from 'components/complex';

// styles
import css from './UserAvatar.module.scss';
import { AuthService } from '../../../services/AuthService';
import { useHistory } from 'react-router-dom';

export const UserAvatar = () => {
  const [expanded, setExpanded] = useState(false);

  const history = useHistory()

  const logout = () => {
    AuthService.logOut()
    history.push('/login')
  }

  const handleToggleExpand = useCallback(() => {
    setExpanded(prev => !prev);
  }, [setExpanded]);
  const { currentUser } = AuthService;


  return (
    <div className={css.root}>
      <Popover
        backdrop
        width={300}
        className={classnames(css.popover)}
        open={expanded}
        side="right"
        onBlur={handleToggleExpand}>
        <div className={css.userInfoTitle}>
          <div className={css.title}>{currentUser?.name}</div>
          <div className={css.subtitle}>{currentUser?.company_name}</div>
          <div className={css.email}>{currentUser?.items?.find(item => item.contact_item_type_name === 'email')?.value || 'N/A'}</div>
        </div>
        <Divider margin={0}/>
        <div className={css.logout} onClick={logout}>
          <div>Logout</div>
          <Icon size="small" name="logout"/>
        </div>
      </Popover>
      <Badge
        className={css.avatar}
        vertical="bottom"
        color="blue"
        content={<Icon size="mini" name={expanded ? 'expand_less' : 'expand_more'}/>}
        onClick={handleToggleExpand}>
        <Avatar src={currentUser?.avatar}/>
      </Badge>
    </div>
  );
};
