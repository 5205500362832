// core
import React, { useState, useEffect } from 'react'

// styles
import css from './Downloads.module.scss'

// components
import { Heading, Icon, Loader} from 'components'

// translation
// import { t } from 'i18n';

import { useHistory } from 'react-router-dom'
import { changeTitle } from '../../routes'
import APIClass, { IAPIResponse } from 'api'
import { IFilesTableEntry } from './Partials/FilesTable'
import { VersionCard } from './Partials/VersionCard'

// services
import { AuthService } from '../../../services/AuthService';

export interface IDist {
  id: string
  version?: string
  title?: string
  phase?: string
  files: IFilesTableEntry[]
  card_open: boolean
}

export const Dist = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<IDist[]>()
  const history = useHistory()

  changeTitle('Downloads - Distributions')

  const loadVersions = () => {
    setLoading(true)
    APIClass.Downloadv2Endpoint.List().then((res: IAPIResponse<unknown> | undefined) => {
      if (res?.status === 403) {
        history.push('/dashboard')
      }
      if (res?.success) {
        setList(res.content as any)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    // @ts-ignore
      if (!AuthService.currentUser?.company?.is_not_payed) {
        loadVersions()
    }
  }, [])

  return (
    <>
      <Heading className={css.heading2} variant="h2" title="Versions" />
      {loading ? (
        <div className={css.full}>
          <Loader />
        </div>
      ) : (
        <div>
          {list?.map((item, i) => (
            <VersionCard key={item.id} item={item} loading={loading} index={i} />
          ))}
        </div>
      )}
      {
        AuthService.currentUser?.company?.is_not_payed ? (

          <div className={css.contentLocked}>
              <Icon size="extraLarge" color="blue" name="info" />
              <h1>Your access to distributions was deactivated.</h1>
              {/*<Button label="Activate" color="blue" onClick={} />*/}
              <p>
                  Reason of deactivation is probably the License expiration.
                  Please contact our Sales support <a href="sales@webswing.org">sales@webswing.org</a> in order to reactivate your access.
              </p>
          </div>
        ): null
      }
    </>
  )
}
