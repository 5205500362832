// core
import React from 'react';

// libraries
import classnames from 'classnames';

// components
import { Button, Card, CardContent, Icon, Tooltip } from 'components';

// styles
import css from '../Dashboard.module.scss';

import { ICompany } from '../../../../api/interfaces/ICompany';
import moment from 'moment-timezone';

export interface IPhoneSupport {
  /**
   * standard version
   */
  company?: ICompany
}

export const PhoneSupport = ({ company }: IPhoneSupport) => {
  const openContactPage = () => {
    window.open('https://www.webswing.org/contact?license=1', '_blank');
  };

  const pin = company?.support_24_7_pin;
  const phone = company?.support_24_7_phone;

  const now = moment().tz('Europe/London');
  const offHours = (now.hour() < 8 || now.hour() > 17);

  return (
    <Card title={`Call-in Support - ${pin ? offHours ? 'Off hours' : 'Active' : 'Disabled'}`} className={css.phoneSupportWidget}>
      <CardContent>
        {pin ? (
          <div>
            <div className={css.phoneSupportHead}>In case you need support during off-hours, use this contact</div>
            <div className={classnames(css.phoneSupportContent, css.mb05, css.info)}>
              <div>Phone</div>
              <div className={css.blue}>{phone}</div>
            </div>
            <div className={classnames(css.phoneSupportContent, css.mb, css.info)}>
              <div>PIN</div>
              <div className={css.white}>{pin}</div>
            </div>
            <div className={css.phoneSupportContent}>
              UK time {now.format('DD/MM/YYYY h:mma')}<br />
              <Tooltip content="Calls outside business hours are charged at contractually agreed rate" position="topLeft" trigger="hover">
                <div className={classnames(css.badge, offHours ? css.red : css.blue)}>
                    <Icon name="info_outlined" size="mini" className={css.icon} />
                    Business hours 8am - 6pm
                </div>
              </Tooltip>
            </div>
          </div>
        ) : (
          <div>
            <div className={css.phoneSupportHead}>Your account is not eligible for call-in support now</div>
            <div className={classnames(css.phoneSupportContent, css.mb)}>In order to enable this service please contact sales</div>
            <div className={classnames(css.phoneSupportContent, css.mb)}>
              <Button color="danger" label="Contact our team" size="small"  onClick={openContactPage} />
            </div>
            <div className={css.phoneSupportContent}>
              UK time {now.format('DD/MM/YYYY h:mma')}<br />
              Business hours 8am - 6pm
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
