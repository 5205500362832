// core
import React from 'react'

// components
import { PageHeader } from 'components'

// translation
import { t } from 'i18n'

// partials
import Statistics from './Partials/Statistics'
import { MyContactWidget } from './Partials/MyContactWidget'

import css from './Dashboard.module.scss'
import { LatestNewsWidget } from './Partials/LatestNewsWidget'
import { LicenseWidget } from './Partials/LicenseWidget'
import { AuthService } from '../../../services/AuthService'
import { changeTitle } from '../../routes'
import { PhoneSupport } from './Partials/PhoneSupport'

export const Dashboard = () => {
  changeTitle('Dashboard')

  return (
    <div>
      <PageHeader title={t.DASHBOARD} />

      <Statistics />
      <div className={css.widgets}>
        <div>
          <MyContactWidget user={AuthService.currentUser} />
        </div>
        <div>
          <MyContactWidget support user={AuthService.currentUser} />
        </div>
        <div>
          <LicenseWidget company={AuthService.currentUser?.company} />
        </div>
        <div>
          <PhoneSupport company={AuthService.currentUser?.company} />
        </div>
        <div>
          <LatestNewsWidget />
        </div>
      </div>
    </div>
  )
}
