// .core
import React from 'react'
// libraries
import cx from 'classnames'
// styles
import css from './Switch.module.scss'

interface ISwitchProps {
  bChecked?: boolean
  bDisabled?: boolean
  className?: string
  classNameLabel?: string
  id?: string
  label?: string
  onChange?(value: boolean): void
}

/**
 * `Switch` component (not using an HTML input)
 */
export const Switch = React.memo(
  ({ bChecked, bDisabled, className, classNameLabel, id, label, onChange }: ISwitchProps) => {
    const _onChange = () => {
      !bDisabled && onChange && onChange(!bChecked)
    }

    return (
      <div
        className={cx(css.wSwitch, bDisabled && css.disabled, className)}
        id={id}
        onClick={_onChange}>
        {/* SWITCH TOGGLE */}
        <div className={css.toggle}>
          <div className={css.slider}>
            <div className={cx(css.thumb, bChecked && css.thumbActive)} />
          </div>
        </div>

        {/* LABEL */}
        {label && (
          <label className={cx(classNameLabel)} htmlFor={id}>
            {label}
          </label>
        )}
      </div>
    )
  }
)

Switch.displayName = 'Switch'
