// API
// import { IMPORTED_INDIVIDUAL_INTERFACES_FROM_BE_FOR_ALL_BELOW_FORMS_SHOULD_REPLACE_CUSTOM_BELOW_DEFINED_INTERFACES } from 'api'
// i18n
import { t } from 'i18n'
// libraries
import * as Yup from 'yup'

export interface IRequestVerificationForm {
  company: string
  software: string
}

//
// ==================== REQUEST VERIFICATION ====================
export class RequestVerificationForm {
  INITIAL_VALUES: IRequestVerificationForm = {
    company: '',
    software: ''
  }

  VALIDATION = Yup.object().shape<IRequestVerificationForm>({
    company: Yup.string().required(t.VALIDATION.REQUIRED),
    software: Yup.string().required(t.VALIDATION.REQUIRED)
  })
}
