// core
import React from 'react'

// components
import { Chip, formatDate, Tooltip } from 'components';

// styles
import css from '../Issues.module.scss'

export interface IDateChipProps {
  value: string
  limit: string
  tooltip: string
}

export const ResponseResolution = ({ value, limit, tooltip }: IDateChipProps) => {
  return (
    <Tooltip content={tooltip}>
        <div className={css.chipWithDate}>
            {formatDate(value, {format: 'DD.MM.YYYY HH:mm'})}
            <Chip className={css.chip} label={limit} />
        </div>
    </Tooltip>
  )
}
