import React from 'react';
import css from '../Login/LoginPage.module.scss';
import { SettingsService } from '../../../services/SettingsService';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from '@icons-pack/react-simple-icons';

const Footer = observer((props: { center?: boolean }) => (
  <>
    {
      (
        SettingsService.socials?.facebook
        || SettingsService.socials?.twitter
        || SettingsService.socials?.linkedin
        || SettingsService.socials?.instagram
        || SettingsService.socials?.youtube
      ) ? (
        <div className={cx(css.followWrapper, props.center && css.center)}>
          <span>Follow us on</span>
          <div>
            {SettingsService.socials?.facebook ? (
              <a href={SettingsService.socials.facebook} target="_blank" rel="noreferrer noopener">
                <Facebook size={12}/>
              </a>
            ) : null}
            {SettingsService.socials?.twitter ? (
              <a href={SettingsService.socials.twitter} target="_blank" rel="noreferrer noopener">
                <Twitter size={12}/>
              </a>
            ) : null}
            {SettingsService.socials?.linkedin ? (
              <a href={SettingsService.socials.linkedin} target="_blank" rel="noreferrer noopener">
                <Linkedin size={12}/>
              </a>
            ) : null}
            {SettingsService.socials?.instagram ? (
              <a href={SettingsService.socials.instagram} target="_blank" rel="noreferrer noopener">
                <Instagram size={12}/>
              </a>
            ) : null}
            {SettingsService.socials?.youtube ? (
              <a href={SettingsService.socials.youtube} target="_blank" rel="noreferrer noopener">
                <Youtube size={12}/>
              </a>
            ) : null}
          </div>
        </div>
      ) : null
    }
    <div
      dangerouslySetInnerHTML={{ __html: SettingsService.footer.replace('{year}', (new Date()).getFullYear().toString()) }}
      className={cx(css.footer, props.center && css.center)}
    />
  </>
));

export default Footer;
