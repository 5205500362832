// core
import React from 'react'
// import cx from 'classnames';
import css from './Downloads.module.scss'

// components
import { Card, CardContent } from 'components'

import { changeTitle } from '../../routes'
import { JavaTable } from './Partials/JavaTable'
import { WebswingVersionTable } from './Partials/WebswingVersionTable'
import { WebswingVersionABTable } from './Partials/WebswingVersionABTable'
import { listRules } from './Partials/data'

export const Plan = () => {
  changeTitle('Downloads - Release Plan')

  return (
    <>
      <Card title="Release cycle - Basic rules" className={css.marginBottom}>
        <CardContent>
          <ul className={css.listStyle}>
            {listRules.map((item, i) => (
              <li key={i}>
                <div className={css.listRow}>
                  {item!.rule}
                  <i>{item!.desc}</i>
                </div>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>

      <Card title="Release & Support Timeline" className={css.marginBottom}>
        <CardContent>
          <div style={{ overflowX: 'auto' }}>
            <JavaTable />
            <WebswingVersionTable />
            <WebswingVersionABTable />
          </div>
        </CardContent>
      </Card>
      {/*<span className={css.note}>*/}
      {/*  * Java 8 supported with Professional SLA. With Starter and Standard SLA, supporting of Java*/}
      {/*  8 for respective Webswing version can be purchased separately.*/}
      {/*  <br />*/}
      {/*  ** information will be updated by the end of 2022 depending on the Oracle’s release cycle.*/}
      {/*</span>*/}
    </>
  )
}
