// core
import React from 'react'

// styles
import css from '../Issues.module.scss'

export interface ILabelWithTextProps {
  label: string
  text: React.ReactNode
}

export const LabelWithText = ({ label, text }: ILabelWithTextProps) => {
  return (
    <div className={css.textWrapper}>
      <div className={css.label}>{label}</div>
      <div className={css.text}>{text}</div>
    </div>
  )
}
