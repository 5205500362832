// .core
import React from 'react'
// components
import { DialogDefault, IDialogDefaultProps } from './variants/DialogDefault/DialogDefault'
import { DialogRP } from './variants/DialogRP/DialogRP'

export class Dialog extends React.Component<IDialogDefaultProps> {
  static RP = DialogRP

  render() {
    return <DialogDefault {...this.props} />
  }
}
