// .core
import React from 'react'
// components
import { SkeletonCircle } from './variants/SkeletonCircle'
import { SkeletonLine } from './variants/SkeletonLine'
import { SkeletonSquare } from './variants/SkeletonSquare'
import { SkeletonTable } from './variants/SkeletonTable'
import { ISkeletonProps, SkeletonUI } from './variants/SkeletonUI'

export class Skeleton extends React.Component<ISkeletonProps> {
  static Circle = SkeletonCircle
  static Line = SkeletonLine
  static Square = SkeletonSquare
  static Table = SkeletonTable
  static UI = SkeletonUI

  render() {
    return <SkeletonUI {...this.props} />
  }
}
