// .core
import React from 'react'
// components
import { ButtonDefault, IButtonDefaultProps } from '../ButtonDefault/ButtonDefault'
// libraries
import cx from 'classnames'
// styles
import css from './ButtonGroup.module.scss'

interface IButtonGroupProps {
  buttons: IButtonDefaultProps[]
  className?: string
}

export const ButtonGroup: React.FC<IButtonGroupProps> = ({
  buttons,
  className,
}: IButtonGroupProps) => (
  <div className={cx(css.wButtonGroup, className)}>
    {buttons.map((btn, index) => (
      <ButtonDefault {...btn} key={'btn_' + index} className={cx(css.wSubButton, btn.className)} />
    ))}
  </div>
)
