// API
// import { IMPORTED_INDIVIDUAL_INTERFACES_FROM_BE_FOR_ALL_BELOW_FORMS_SHOULD_REPLACE_CUSTOM_BELOW_DEFINED_INTERFACES } from 'api'
// i18n
import { t } from 'i18n';
// libraries
import * as Yup from 'yup';

export interface IForgottenPasswordForm {
  token?: string | null
  email: string
  password?: string | null
}

export interface ILoginForm {
  email: string
  password: string
  rememberMe: boolean
  user?: string
  captha: string
}

export interface IRegistrationForm {
  email: string
  name: string
  password: string
  tou: boolean
  user?: string
  captha: string
}

//
// ==================== FORGOTTEN PASSWORD ====================
export class ForgottenPasswordForm {


  VALIDATION_INIT = Yup.object().shape<IForgottenPasswordForm>({
    email: Yup.string()
      .required(t.VALIDATION.REQUIRED)
      .email(t.VALIDATION.EMAIL),
  });

  VALIDATION_RESET = Yup.object().shape<IForgottenPasswordForm>({
    email: Yup.string()
      .required(t.VALIDATION.REQUIRED)
      .email(t.VALIDATION.EMAIL),
    password: Yup.string().required()
      .nullable()
      .min(8, t.VALIDATION.PASSWORD_LENGTH(8)),
  });
}

//
// ==================== L O G I N ====================
export class LoginForm {
  INITIAL_VALUES: ILoginForm = {
    email: '',
    password: '',
    captha: '',
    user: '',
    rememberMe: false,
  };

  VALIDATION = Yup.object().shape<ILoginForm>({
    email: Yup.string()
      .required(t.VALIDATION.REQUIRED)
      .email(t.VALIDATION.EMAIL),
    password: Yup.string()
      .required(t.VALIDATION.REQUIRED)
      .min(8, t.VALIDATION.PASSWORD_LENGTH(8)),
    rememberMe: Yup.bool(),
    captha: Yup.string(),
    user: Yup.string(),
  });
}

//
// ==================== R E G I S T R A T I O N ====================
export class RegistrationForm {
  INITIAL_VALUES: IRegistrationForm = {
    email: '',
    name: '',
    password: '',
    captha: '',
    user: '',
    tou: false
  };

  VALIDATION = Yup.object().shape<IRegistrationForm>({
    email: Yup.string()
      .required(t.VALIDATION.REQUIRED)
      .email(t.VALIDATION.EMAIL),
    name: Yup.string()
      .required(t.VALIDATION.REQUIRED),
    password: Yup.string()
      .required(t.VALIDATION.REQUIRED)
      .min(6, t.VALIDATION.PASSWORD_LENGTH(6)),
    tou: Yup.boolean().oneOf([true], 'Terms of use must be accepted'),
    captha: Yup.string(),
    user: Yup.string(),
  });
}
