// .core
import React from 'react'
// libraries
import cx from 'classnames'
// styles
import css from './Line.module.scss'
import { ISpinnerProps } from '../../..';

interface ILoaderLineProps extends ISpinnerProps {
  bLoading?: boolean
  position?: 'bottom' | 'top'
}

/**
 * `Line` variant of animated loading indicator
 */
export const Line = ({ bLoading, position, className }: ILoaderLineProps) => {
  const style: React.CSSProperties = {}

  if (position === 'bottom') {
    style.bottom = 0
  }

  return bLoading ? (
    <div className={cx(css.loaderLine, className)} style={style}>
      <div />
    </div>
  ) : null
}
