// core
import React, { useEffect, useRef, useCallback } from 'react'

import classnames from 'classnames'

// components
import { IWrapperComponentProps } from 'components'

// styles
import css from './Popover.module.scss'
import { Card } from 'components/containers'
import { Backdrop } from 'components/basic/Backdrop/Backdrop'
import { runCallback } from 'utils'

export interface IPopoverProps extends IPopoverComponentProps {
  /**
   * Callback to run on blur
   */
  onBlur?: () => any
  /**
   * Css z-index value
   */
  zIndex?: number
  /**
   * should be displayed backdrop
   */
  backdrop?: boolean
}

export interface IPopoverComponentProps extends IWrapperComponentProps {
  /**
   * Invert colors
   */
  inverted?: boolean
  /**
   * Whether to show content
   */
  open: boolean
  /**
   * From which side to open
   */
  side?: 'left' | 'right'
  /**
   * Width of popover
   */
  width?: number
}

const PopoverComponent = ({
  inverted,
  open,
  side = 'left',
  width,
  className,
  children,
  classes = {},
}: IPopoverProps) => {
  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (rootRef.current) {
      rootRef.current.style.width = open ? '100%' : '0px'
    }
  }, [open, children])

  return (
    <div
      ref={rootRef}
      className={classnames(
        css.root,
        {
          [css.open]: open,
        },
        css[side],
        className
      )}>
      <Card className={classnames(css.card, classes.card)}>
        <div className={classnames({ [css.boxInverted]: inverted })}>
          <div className={classnames(css.content, classes.content)} style={{ width }}>
            {children}
          </div>
        </div>
      </Card>
    </div>
  )
}

export const Popover = ({
  open,
  side = 'left',
  onBlur,
  backdrop,
  className,
  classes = {},
  zIndex = 400,
  width: requestedWidth,
  ...passingProps
}: IPopoverProps) => {
  const popoverPositionRef = useRef<HTMLDivElement>(null)

  const popoverWidth = requestedWidth || 400

  const handleBlur = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()

      runCallback(onBlur)
    },
    [onBlur]
  )

  return (
    <div ref={popoverPositionRef} className={classnames(css.refDiv, css[side], className)}>
      {backdrop ? (
        <Backdrop
          className={classes.backdrop}
          visible={open}
          zIndex={zIndex}
          onClick={handleBlur}
        />
      ) : null}
      <PopoverComponent
        className={classes.popover}
        classes={classes}
        open={open}
        side={side}
        width={popoverWidth}
        {...passingProps}
      />
    </div>
  )
}
