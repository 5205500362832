// .core
import React, { useEffect, useState } from 'react';
// libraries
import cx from 'classnames'
import RCSelect, { ValueType, ActionMeta } from 'react-select'
import Creatable from 'react-select/creatable'
// styles
import css from './Select.module.scss'
// utils
import { ISelectItem, parseArray } from 'utils'
// declarations
type TValue = ISelectItem // T

interface ISelectProps {
  bAutoFocus?: boolean
  bDisabled?: boolean
  bMulti?: boolean
  bReturnOnlyValues?: boolean
  bSearchable?: boolean
  bCreatable?: boolean
  bClearable?: boolean
  className?: string
  classNameSelect?: string
  defaultValue?: TValue
  defaultInputValue?: string
  id?: string
  label?: string | React.ReactNode
  name?: string
  options?: TValue[]
  placeholder?: string
  value?: TValue | TValue[]
  onChange?(value: TValue | TValue[] | string[] | null): void
}

export const Select = ({
  bAutoFocus,
  bDisabled,
  bMulti,
  bReturnOnlyValues,
  bSearchable,
  bCreatable,
  bClearable,
  className,
  classNameSelect,
  defaultValue,
  defaultInputValue,
  id,
  label,
  name,
  options,
  placeholder,
  value,
  onChange,
}: ISelectProps) => {
  const [stateValue, setStateValue] = useState();

  useEffect(() => {
    setStateValue(value);
  }, [value])

  /**
   * Retrieves a prop from `ISelectItem` structure to use as a `label` for options
   */
  const _getOptionLabel = (option: ISelectItem): string => option.name
  /**
   * Retrieves a prop from `ISelectItem` structure to use as a `value` for options
   */
  const _getOptionValue = (option: ISelectItem): string => option.id

  const _onChange = (value: ValueType<ISelectItem>, _actionMeta: ActionMeta<ISelectItem>) => {
    if (bDisabled) return

    if (bReturnOnlyValues) {
      // #NOTE: `RCSelect` upon clearing the input/value returns `null` instead of `[]`
      onChange?.(parseArray((value || []) as ISelectItem[]).extract(['id']) as string[])
      return
    }

    setStateValue(value as any);
    onChange?.(value as any)
  }

  function _getNewOptionData(inputValue: string, optionLabel: React.ReactNode) {
    return {
      id: inputValue,
      name: optionLabel as string,
      __isNew__: true
    }
  }

  return (
    <div className={cx(css.wSelect, className)}>
      <label htmlFor={id}>{label}</label>

      {bCreatable ? (
        <Creatable
          name={name}
          autoFocus={bAutoFocus}
          className={classNameSelect}
          classNamePrefix="wSelect"
          defaultValue={defaultValue}
          defaultInputValue={defaultInputValue}
          id={id}
          // isDisabled={bDisabled || !options?.length}
          isDisabled={!!bDisabled}
          isClearable={bClearable}
          isMulti={bMulti}
          isSearchable={bSearchable}
          options={options}
          placeholder={placeholder}
          value={stateValue}
          getOptionLabel={_getOptionLabel}
          getOptionValue={_getOptionValue}
          getNewOptionData={_getNewOptionData}
          onChange={_onChange}
          onMenuOpen={() => setStateValue(null)}
        />
      ) : (
        <RCSelect
        name={name}
        autoFocus={bAutoFocus}
        className={classNameSelect}
        classNamePrefix="wSelect"
        defaultValue={defaultValue}
        defaultInputValue={defaultInputValue}
        id={id}
        isDisabled={bDisabled || !options?.length}
        isClearable={bClearable}
        isMulti={bMulti}
        isSearchable={bSearchable}
        options={options}
        placeholder={placeholder}
        value={value}
        getOptionLabel={_getOptionLabel}
        getOptionValue={_getOptionValue}
        onChange={_onChange}
      />
      )}
    </div>
  )
}
