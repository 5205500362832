// .core
import React from 'react'
// libraries
import cx from 'classnames'
import { FlexProperty } from 'csstype'
// styles
import css from './Grid.module.scss'

export interface IGridProps {
  alignItems?: 'start' | 'center' | 'end' | 'baseline' | 'stretch'
  /**
   * Whether to add `.wColumn` class
   */
  bColumn?: boolean
  children: React.ReactNode
  className?: string
  flex?: FlexProperty<undefined>
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | 'initial' | 'inherit'
  /**
   * Adds `.itemsPerRow(number)` class and sets `flexWrap: 'wrap'` to the `Grid`
   */
  itemsPerRow?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 12
  justifyContent?:
    | 'start'
    | 'center'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'space-around'
    | 'space-between'
  /**
   * Adds `.spacing` and `.spacing(number)` class - adds `margin-right` for `rows` and `margin-bottom` for `columns`
   * onto every `Grid`'s child
   */
  spacing?: 0.5 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  style?: React.CSSProperties
  onClick?(): void
}

export const Grid: React.FC<IGridProps> = (props: IGridProps) => {
  const {
    alignItems,
    bColumn,
    children,
    className,
    flex,
    flexWrap,
    itemsPerRow,
    justifyContent,
    spacing,
    style,
    onClick,
  } = props

  const classNames = cx(
    css.wGrid,
    bColumn ? css.wColumn : css.wRow,
    spacing && [css.spacing, css['spacing' + spacing]],
    itemsPerRow && css['itemsPerRow' + itemsPerRow],
    onClick && 'gClickable',
    className
  )

  const styles: React.CSSProperties = {
    alignItems,
    justifyContent,
    flex,
    flexWrap: itemsPerRow ? 'wrap' : flexWrap,
    // width: itemsPerRow ? (100 / itemsPerRow) + '%' : 'unset'
    ...style,
  }

  const _onClick = () => {
    onClick?.()
  }

  return (
    <div className={classNames} style={styles} onClick={_onClick}>
      {children}
    </div>
  )
}
