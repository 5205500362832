// core
import React, { useState } from 'react'
import cx from 'classnames'

// styles
import css from '../Downloads.module.scss'

// components
import { Button, Grid, Icon, TableVirtualized } from 'components'

// translation
// import { t } from 'i18n';
import { humanFileSize } from 'pages/core/Issues/IssueDetail'
import { observer } from 'mobx-react'
import { DownloadsService } from 'services/DownloadsService'
import APIClass from 'api'
import API from "../../../../api/APIClass";

interface IFilesTableProps {
  collection: IFilesTableEntry[] | undefined
  loading: boolean
}

export interface IFilesTableEntry {
  name: string
  fileType: string
  versionType: string
  version: string
  size: string
  isDownloadable: boolean
}

export const FilesTable = observer(({ collection, loading }: IFilesTableProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const download = (file: string, isDownloadable: boolean) => {
    if (isDownloadable) {
        APIClass.Downloadv2Endpoint.downloadFile(file).then(res => {
            if (res?.success && res.content) {
                window.open(res.content as string, 'Download');
            }
        });
    }
  }

  return (
    <>
      <div style={{ overflowX: 'auto' }}>
        <TableVirtualized
          className={css.filesTable}
          collection={collection}
          bLoading={loading}
          minTableWidth={850}
          heightTable={
            expanded && collection!.length * 60 + 50 >= 350 ? collection!.length * 60 + 50 : 350
          }
          columns={[
            {
              dataKey: 'name',
              label: 'Filename',
              align: 'start',
              minWidth: 300,
              component: (name, row) => (
                <div className={cx(css.fileCell, { [css.disabled]: !row.isDownloadable })} onClick={()=>download(row.name, row.isDownloadable as boolean)}>
                  <Icon name="insert_drive_file" color="blue" />
                  {name || ''}
                </div>
              ),
            },
            {
              dataKey: 'version',
              label: 'Version',
              align: 'start',
              minWidth: 100,
              component: (version, row) => (
                <div className={cx({ [css.disabled]: !row.isDownloadable })}>{version || ''}</div>
              ),
            },
            {
              dataKey: 'versionType',
              label: 'Release',
              align: 'start',
              minWidth: 100,
              component: (version, row) => (
                <div className={cx({ [css.disabled]: !row.isDownloadable })}>
                  {DownloadsService.getName(version as string || '')}
                </div>
              ),
            },
            {
              dataKey: 'size',
              label: 'Filesize',
              align: 'start',
              minWidth: 100,
              component: (filesize, row) => (
                <div className={cx({ [css.disabled]: !row.isDownloadable })}>
                  {filesize ? humanFileSize(Number(filesize)) : '-'}
                </div>
              ),
            },
            {
              dataKey: 'isDownloadable',
              label: ' ',
              minWidth: 50,
              component: (isDownloadable, row) => (
                <div
                  className={cx({ [css.disabled]: !isDownloadable })}
                  style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <a href="javascript:void(0);" onClick={()=>download(row.name, isDownloadable as boolean)}>
                    <Icon name="arrow_circle_down" color="blue" size="large" />
                  </a>
                </div>
              ),
            },
          ]}
        />
      </div>
      {collection!.length * 60 + 50 >= 350 && collection!.length >5 && (
        <Grid className={css.btnSpacing} justifyContent="center">
          <Button
            size="small"
            iconColor="white"
            label={expanded ? 'show less' : 'show more'}
            onClick={() => setExpanded(e => !e)}
          />
        </Grid>
      )}
    </>
  )
})
