// core
import React from 'react';

// libraries
import moment from 'moment-timezone';

// components
import { Chip } from 'components';

// styles
import css from './DateString.module.scss';

export type IDateValueType = Date | number | string | null | undefined

export interface IDateProps {
  /**
   * Date value to format
   */
  value: IDateValueType

  /**
   * Format date to this
   */
  format?: string

  /**
   * Fallback string for invalid date
   */
  fallback?: string
  /**
   * date to count days, weeks or months from
   */
  dateTo?: Date | number
  /**
   * align content
   */
  align?: string
}

export const formatDate = (
  value?: Date | number | string | null,
  options?: {
    format?: string
    fallback?: string
  },
): string => {
  let format = 'DD.MM.YYYY';
  let fallback = 'N/A';

  if (options?.format) {
    format = options.format;
  }

  if (options?.fallback) {
    fallback = options.fallback;
  }

  return value ? moment.tz(value, moment.tz.guess()).format(format) : fallback;
};

export const DateString = ({ value, format, fallback, dateTo, align }: IDateProps) => {
  let dateB;
  let timeType: 'days' | 'months' | 'weeks' = 'days';
  if (dateTo) {
    const momentDateTo = moment(dateTo);
    dateB = Math.abs(moment(value).diff(momentDateTo, timeType));

    if (dateB > 7 && dateB <= 31) timeType = 'weeks';
    else if (dateB > 31) timeType = 'months';

    dateB = Math.abs(moment(value).diff(momentDateTo, timeType));
  }

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: (align || 'flex-start') }}>
      {formatDate(value, { format, fallback })}{' '}
      {dateTo ? <Chip className={css.chip} label={`${dateB} ${timeType}`}/> : ''}
    </div>
  );
};
