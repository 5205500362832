// core
import React, { memo, useCallback } from 'react'

import classnames from 'classnames'

// components
import { IComponentProps } from 'components'
import { Icon } from 'components/basic/Icon/Icon'

// utils
import { runCallback } from 'utils/functions'

// styles
import css from './CheckboxField.module.scss'

export interface ICheckboxFieldProps extends IComponentProps {
  /**
   * Whether to invert colours
   */
  inverted?: boolean
  /**
   * Whether to disable field
   */
  disabled?: boolean
  /**
   * Color of checkbox when checkedd
   */
  color?: 'primary' | 'neutral' | 'success' | 'warning' | 'danger'
  /**
   * Use custom icon when checked
   */
  icon?: string
  /**
   * Callback to run on checkbox click
   */
  onClick?: (oldValue: boolean) => void
  /**
   * Whether it should be checked
   */
  checked: boolean
}

export const CheckboxField = memo(function CheckboxField({
  inverted,
  color = 'primary',
  icon,
  disabled = false,
  onClick,
  className,
  checked = false,
}: ICheckboxFieldProps) {
  const handleClick = useCallback(
    (event: any) => {
      event.preventDefault()
      event.stopPropagation()

      runCallback(onClick, checked)
    },
    [onClick, checked]
  )

  const iconComponent = <Icon className={css.checkIcon} name={icon || 'check'} />

  return !disabled ? (
    // eslint-disable-next-line
    <a
      className={classnames(
        css.root,
        checked ? css.checked : '',
        css[color],
        inverted ? css.inverted : '',
        className
      )}
      href="#"
      onClick={handleClick}>
      {iconComponent}
    </a>
  ) : (
    <div
      className={classnames(
        css.root,
        checked ? css.checked : '',
        css.disabled,
        inverted ? css.inverted : '',
        className
      )}>
      {iconComponent}
    </div>
  )
})
