// core
import React, { useState, useRef, useEffect } from 'react'

// translation
// import { t } from 'i18n'

// partials
import css from './Chatbot.module.scss'
import poof from 'assets/animations/pdr/owen_pdr_zmiznutie_alpha.mov'
import fall from 'assets/animations/pdr/owen_pdr_pad_alpha.mov'
import main from 'assets/animations/pdr/owen_pdr_alpha.mov'
import poofWebm from 'assets/animations/pdr/owen_pdr_zmiznutie_alpha.webm'
import fallWebm from 'assets/animations/pdr/owen_pdr_pad_alpha.webm'
import mainWebm from 'assets/animations/pdr/owen_pdr_alpha.webm'
import poofHeic from 'assets/animations/pdr/owen_pdr_zmiznutie_alpha.hevc.mp4'
import fallHeic from 'assets/animations/pdr/owen_pdr_pad_alpha.hevc.mp4'
import mainHeic from 'assets/animations/pdr/owen_pdr_alpha.hevc.mp4'
import posterFall from 'assets/animations/poster.png'
import owenPhoto from 'assets/images/Avatar.png'
// @ts-ignore
import { Player, ControlBar, Shortcut } from 'video-react'
import { OwenDialog } from './Partials/OwenDialog'
import { Chip, Avatar } from 'components'
import { AuthService } from 'services/AuthService'
import APIClass from 'api'
import { isSafari } from 'react-device-detect'

export const Chatbot = () => {
  const ref = useRef()
  const [cornerSrc, setCornerSrc] = useState<{ mov: string; webm: string } | null>(null)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [cornerInterval, setCornerInterval] = useState<any>(false)
  const [fallTimeout, setFallTimeout] = useState<any>(false)

  const setSource = (src: { mov: string; webm: string }) => {
    setCornerSrc(src)
    // @ts-ignore
    ref?.current?.load()
  }

  useEffect(() => {
    if (AuthService.currentUser?.owen.current_step === 'first') APIClass.OwenEndpoint.Shown()
  }, [])

  const cornerAnimation = () => {
    setSource({ mov: main, webm: isSafari ? mainHeic : mainWebm })

    setFallTimeout(
      setTimeout(() => {
        setSource({ mov: fall, webm: isSafari ? fallHeic : fallWebm })

        setTimeout(() => {
          setCornerSrc(null)
        }, 1000)
      }, 15000)
    )
  }

  const cornerIntervalStart = () => {
    setTimeout(() => {
      cornerAnimation()

      setCornerInterval(
        setInterval(() => {
          cornerAnimation()
        }, 21000)
      )
    }, 1000)
  }

  useEffect(() => {
    new Image().src = posterFall
    cornerIntervalStart()
  }, [])

  const handleClick = () => {
    APIClass.OwenEndpoint.Opened()
    setSource({ mov: poof, webm: isSafari ? poofHeic : poofWebm })
    clearInterval(cornerInterval)
    clearTimeout(fallTimeout)

    setTimeout(() => {
      setCornerSrc(null)
      setShowDialog(true)
    }, 300)
  }

  const handleClose = (stop?: boolean) => {
    setShowDialog(false)
    AuthService.refreshUserInfo()

    if (!stop) {
      cornerIntervalStart()
    }
  }

  return (
    <div className={css.root}>
      {cornerSrc && (
        <div className={css.owen} onClick={handleClick}>
          <Player
            ref={ref}
            muted
            autoPlay
            playsInline
            preload="auto"
            poster={cornerSrc.mov === fall ? posterFall : undefined}
            width={222}
            height={356}
            fluid={false}
            className={css.player}>
            <source
              src={cornerSrc.webm}
              type={isSafari ? 'video/mp4; codecs=hevc' : 'video/webm'}
            />
            <source
              src={cornerSrc.mov}
              type="video/mp4"
              // @ts-ignore
              codecs="hvc1"
            />
            <ControlBar disableCompletely className="my-class" />
            <Shortcut dblclickable={false} clickable={false} />
          </Player>
        </div>
      )}

      <Chip
        className={css.owenMobile}
        color="blue"
        label={
          <>
            <Avatar className={css.avatar} size="small" src={owenPhoto} /> Let&apos;s chat with Owen
          </>
        }
        onClick={handleClick}
      />

      <OwenDialog showDialog={showDialog} onClose={handleClose} />
    </div>
  )
}
