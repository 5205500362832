// .core
import React from 'react'
// components
import { ButtonDefault, IButtonDefaultProps } from './variants/ButtonDefault/ButtonDefault'
import { ButtonGroup } from './variants/ButtonGroup/ButtonGroup'
import { ButtonUI } from './variants/ButtonUI/ButtonUI'
import { FAB } from './variants/FAB/FAB'

export class Button extends React.Component<IButtonDefaultProps> {
  static Default = ButtonDefault
  static Group = ButtonGroup
  static FAB = FAB
  static UI = ButtonUI

  render() {
    return <ButtonDefault {...this.props} />
  }
}
