// .core
import React from 'react'
// libraries
import cx from 'classnames'
// styles
import css from '../Skeleton.module.scss'

export interface ISkeletonProps {
  bLoading: boolean
  children: any // React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export const SkeletonUI: React.FC<ISkeletonProps> = ({ bLoading, children }: ISkeletonProps) => {
  return bLoading
    ? React.Children.map(children, (child, index) => {
        return (
          <div style={{ position: 'relative' }}>
            <div
              className={cx(css.item, css.itemAnimation)}
              style={{ animationDelay: `${0.05 * index}s` }}
            />
            {child}
          </div>
        )
      })
    : children
}
