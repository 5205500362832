// .core
import React from 'react'
// libraries
import cx from 'classnames'
// styles
import css from './Divider.module.scss'

interface IDividerProps {
  className?: string
  margin?: number
  vertical?: boolean
}

/**
 * Simple line that divides containers w/ custom margin and vertical or horizontal placement
 */
export const Divider = ({ className, vertical = false, margin = 5 }: IDividerProps) => (
  <div
    className={cx(css.wDivider, vertical && css.wVertical, className)}
    style={{ margin: vertical ? `0 ${margin}px` : `${margin}px 0` }}
  />
)
