import React from 'react'
import { Avatar, Chip } from 'components'
import classnames from 'classnames'
import css from '../Chatbot.module.scss'
import owenPhoto from 'assets/images/Avatar.png'
import { Thumbnail } from './Thumbnail'
import { AuthService } from 'services/AuthService'

interface IMessageGroupProps {
  group: {
    content: string | any[]
    person: string
    type?: string
    answer?: string | string[]
    typing?: boolean
  }[]
}

export const MessageGroup = ({ group }: IMessageGroupProps) => {
  const owenGroup = group.length && group[0].person.toLowerCase() === 'owen'

  const { currentUser } = AuthService
  return (
    <div className={classnames(css.group, { [css.own]: !owenGroup })}>
      <Avatar
        className={css.avatar}
        size="small"
        src={!owenGroup ? currentUser?.avatar : owenPhoto}
      />
      <div>
        {group.map(({ content, answer, typing, type }, index) => {
          return answer && typeof answer !== 'string' ? (
            <div key={index} className={classnames(css.optionsChipWrap)}>
              {answer.map(item => (
                <Chip
                  key={item}
                  className={classnames(css.optionsChip, css.selected, css.answer)}
                  type="outline"
                  color="white"
                  label={item as string}
                />
              ))}
            </div>
          ) : typeof content === 'string' ? (
            <div key={index} className={classnames(css.message)}>
              {type === 'image' ? (
                <Thumbnail src={content} alt="image" />
              ) : (
                <p className={css.content} dangerouslySetInnerHTML={{ __html: content }} />
              )}
            </div>
          ) : type === 'links' ? (
            <div key={index} className={classnames(css.optionsChipWrap, css.anchorsChipWrap)}>
              {content.map(item => (
                <a
                  key={item.content as string}
                  href={
                    (item.href as string).startsWith('mailto:')
                      ? item.href + item.content
                      : item.href
                  }
                  target="_blank"
                  rel="noreferrer noopener">
                  <Chip
                    className={classnames(css.optionsChip)}
                    type="outline"
                    color="white"
                    label={item.content as string}
                  />
                </a>
              ))}
            </div>
          ) : answer ? (
            <div key={index} className={classnames(css.message)}>
              <p className={css.content}>{answer}</p>
            </div>
          ) : typing ? (
            <div key={index} className={css.message}>
              <div className={css.typeBlock}>
                <div className={css.typeDot} />
                <div className={css.typeDot} />
                <div className={css.typeDot} />
              </div>
            </div>
          ) : null
        })}
      </div>
    </div>
  )
}
