// API
// import { IMPORTED_INDIVIDUAL_INTERFACES_FROM_BE_FOR_ALL_BELOW_FORMS_SHOULD_REPLACE_CUSTOM_BELOW_DEFINED_INTERFACES } from 'api'
// i18n
import { t } from 'i18n'
// libraries
import * as Yup from 'yup'

export interface IOwenForm {
  input: string
}

//
// ==================== REQUEST VERIFICATION ====================
export const OwenForm = {
  INITIAL_VALUES: {
    input: '',
  },

  VALIDATION: Yup.object().shape<IOwenForm>({
    input: Yup.string().required(t.VALIDATION.REQUIRED),
  }),
}
