// core
import React from 'react'

// components
import { IComponentProps } from 'components'
import { TableVirtualized, ITableProps } from './TableVirtualized'

// styles
import css from './Table.module.scss'

export interface IHeaderProps extends IComponentProps {
  flex?: number
  name: string
  id: string
}
export interface ITableVirtualizedProps extends IComponentProps, Omit<ITableProps<any>, 'header'> {
  header: IHeaderProps[]
}

export const Table = ({ columns, collection, header, ...passingProps }: ITableVirtualizedProps) => {
  return (
    <div className={css.horizontalScroll}>
      <TableVirtualized
        {...passingProps}
        header={
          <div className={css.tableHeader}>
            {header.map(({ id, name, flex }) => (
              <div key={id} style={{ display: 'flex', flex: flex || 1 }}>
                {name}
              </div>
            ))}
          </div>
        }
        collection={collection}
        columns={columns}
      />
    </div>
  )
}
