// core
import React, { ReactNode } from 'react'

import classnames from 'classnames'

// components
import { IWrapperComponentProps } from 'components'

import { Chip } from '../Chip/Chip'
import { Coin, ICoinProps } from '../Coin/Coin'

// style
import css from './Badge.module.scss'

export interface IBadgeProps extends IWrapperComponentProps, ICoinProps {
  /**
   * Display content in badge
   */
  content: null | number | string | ReactNode
  /**
   * Choose side to render badge
   */
  side?: 'left' | 'right'
  /**
   * Choose where verticaly render badge
   */
  vertical?: 'top' | 'bottom'
  /**
   * onClick function
   */
  onClick: () => void
}

/**
 * Count badge displayed at the top corner for any component which it wraps
 */
export const Badge = ({
  content,
  side = 'right',
  vertical = 'top',
  color = 'primary',
  size = 'medium',
  children,
  className,
  onClick,
  classes = {},
}: IBadgeProps) => {
  const shouldRenderCoin = !(typeof content === 'string' && content.length >= 2)
  const BadgeComponent = shouldRenderCoin ? Coin : Chip

  return (
    <div
      className={classnames(css.root, css[side], css[vertical], css[color], className)}
      onClick={onClick}>
      {children}

      {content ? (
        <BadgeComponent
          className={classnames(css.badge, css[size], classes.badge)}
          color={color}
          size={size}>
          {content}
        </BadgeComponent>
      ) : null}
    </div>
  )
}
