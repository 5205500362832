// API
import API from 'api';
import { observable } from 'mobx';

interface ISettingsResponse {
  footer: string
  facebook: string | null
  twitter: string | null
  linkedin: string | null
  instagram: string | null
  youtube: string | null
}

class SettingsServiceClass {
  @observable
  footer: string = '';

  @observable
  socials: ISettingsResponse | undefined;

  loadSettings() {
    API.SettingsEndpoint.Footer()
      .then(res => {
        if (res?.success) {
          this.footer = (res.content as ISettingsResponse).footer;
          this.socials = (res.content as ISettingsResponse);
        }
      });
  }
}

export const SettingsService = new SettingsServiceClass();
