// .core
import React from 'react'
// components
import { InputDefault, IInputDefaultProps } from './variants/InputDefault/InputDefault'
// ---variants
import { Checkbox } from './variants/Checkbox/Checkbox'
import { DatePicker } from './variants/DatePicker/DatePicker'
import { InputField } from './variants/InputField/InputField'
import { Radio } from './variants/Radio/Radio'
import { Select } from './variants/Select/Select'
import { Switch } from './variants/Switch/Switch'

export class Input extends React.Component<IInputDefaultProps> {
  static Checkbox = Checkbox
  static DatePicker = DatePicker
  static Field = InputField
  static Radio = Radio
  static Select = Select
  static Switch = Switch

  render() {
    return <InputDefault {...this.props} />
  }
}
