// core
import React, { useCallback, useEffect, useState } from 'react'

// libs
import { clone } from 'lodash'

// styles
import css from './Downloads.module.scss'

// components
import {
  PageHeader,
  Icon,
  DateString,
  TableVirtualized,
  Breadcrumbs,
  BreadcrumbsItemType,
  IDateValueType,
  IColumns,
} from 'components'

// translation
import { t } from 'i18n'

import { useParams, useHistory } from 'react-router-dom'
import APIClass from '../../../api'
import { changeTitle } from '../../routes'
import { DownloadCell } from './Partials/DownloadCell'
import { humanFileSize } from '../Issues/IssueDetail'
import { Index } from 'react-virtualized'

export interface IFolderEntry {
  is_file: boolean
  is_preview?: boolean
  description?: string
  link: string
  filename: string
  last_modified: string | null
  filesize: number | null
}

export const DownloadsOld = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<IFolderEntry[]>([])
  const [columns, setColumns] = useState<IColumns<IFolderEntry>[]>([])
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsItemType[]>([])
  const { folder } = useParams()
  const history = useHistory()

  changeTitle('Downloads')

  const defaultColumns: IColumns<IFolderEntry>[] = [
    {
      dataKey: 'filename',
      label: 'Folder / File name',
      align: 'start',
      minWidth: 450,
      component: (filename, row) => (
        <DownloadCell
          isFile={row.is_file}
          isPreview={row.is_preview || false}
          name={filename as string}
          to={row.link}
        />
      ),
    },
  ]

  const updateColumns = useCallback(() => {
    const columnsSet = clone(defaultColumns)
    if (!folder) {
      columnsSet.splice(1, 0, {
        dataKey: 'description',
        label: 'Description',
        align: 'start',
        minWidth: 300,
        component: description => <div>{description || ''}</div>,
      })
    }

    if (list.filter(item => item.is_file).length > 0) {
      columnsSet.splice(
        1,
        0,
        {
          dataKey: 'last_modified',
          label: 'Updated',
          minWidth: 125,
          component: lastModified => (
            <DateString
              align="center"
              value={lastModified as IDateValueType}
              format="DD.MM.YYYY HH:mm"
              fallback="-"
            />
          ),
        },
        {
          dataKey: 'filesize',
          label: 'Size',
          align: 'end',
          minWidth: 100,
          component: filesize => (
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              {filesize ? humanFileSize(filesize as number) : '-'}
            </div>
          ),
        },
        {
          dataKey: 'link',
          label: ' ',
          minWidth: 50,
          component: (link, row) => (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              {row.is_file ? (
                <a href={link as string} target="_blank" rel="noopener noreferrer">
                  <Icon name="arrow_circle_down" color="blue" size="large" />
                </a>
              ) : null}
            </div>
          ),
        }
      )
    }
    setColumns(columnsSet)
  }, [defaultColumns, list, folder])

  const loadFolder = useCallback(
    (folder: string) => {
      setLoading(true)
      APIClass.DownloadEndpoint.List(folder).then(res => {
        if (res?.status === 403) {
          history.push('/dashboard')
        }
        if (res?.success) {
          setList(res.content as IFolderEntry[])
        }
        setLoading(false)
      })
    },
    [history]
  )

  useEffect(() => {
    updateColumns()
  }, [list])

  useEffect(() => {
    loadFolder(folder ? `${folder}/` : folder)
    const root = { title: '/root', path: `/downloads` }
    if (folder) {
      let last = '/downloads'
      const bc = folder.split('/').map((item: string) => {
        last = `${last}/${item}`
        return { title: item, path: last }
      })
      bc.unshift(root)
      setBreadcrumbs(bc)
    } else {
      setBreadcrumbs([root])
    }
    updateColumns()
  }, [folder, loadFolder])

  const rowClassName = useCallback(
    (info: Index) => {
      if (!list.hasOwnProperty(info.index)) {
        return ''
      }
      return list[info.index].is_preview ? css.rowPreview : 'pointer'
    },
    [list]
  )

  return (
    <div>
      <PageHeader title={t.DOWNLOADS} />
      <Breadcrumbs items={breadcrumbs} />
      <div style={{ overflowX: 'auto' }}>
        <TableVirtualized
          collection={list}
          bLoading={loading}
          minTableWidth={850}
          columns={columns}
          rowClassName={rowClassName}
          onRowClick={info => {
            const { rowData } = info!
            if (rowData.is_file) {
              // window.open(rowData.link, '_blank')
            } else if (rowData.is_preview) {
              // do nothing
            } else {
              history.push(`/downloads/${rowData.link}`)
            }
          }}
        />
      </div>
    </div>
  )
}
