// .core
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
// styles
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import 'vanilla-cookieconsent/dist/cookieconsent.js'
import './index.css'
// utils
import * as serviceWorker from './serviceWorker'
// import { init as initAnalytics  } from 'utils/analytics'

// initAnalytics()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
