// core
import React from 'react';

// components
import { Card, Heading, Input, List, Loader } from 'components';

// translation
// import { t } from 'i18n'
// styles
import css from '../Settings.module.scss';
import APIClass from '../../../../api';
import { IClientPortalRole } from '../../../../api/interfaces/IUser';
import { IPageProps, ISelectItem } from '../../../../utils';
import { t } from '../../../../i18n';

interface IAccessRightsState {
  loaded: boolean
  loadingPermissions: string[]
  roles: IClientPortalRole[]
  activeRole: IClientPortalRole | undefined
}

export default class AccessRights extends React.Component<{}, IAccessRightsState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
      loaded: false,
      loadingPermissions: [],
      roles: [],
      activeRole: undefined,
    };
  }

  loadRoles() {
    APIClass.SettingsEndpoint.RolesPortal().then(res => {
      if (res?.success) {
        const roles = res.content as IClientPortalRole[];
        let activeRole = undefined;
        if (roles.length) {
          activeRole = roles[0];
        }
        this.setState({ roles, activeRole });
      }
      this.setState({ loaded: true });
    });
  }

  componentDidMount() {
    this.loadRoles();
  }

  handleRoleChange = (pickedRole: ISelectItem) => {
    if (true === pickedRole.__isNew__) {
      APIClass.SettingsEndpoint.CreatePortalRole({ name: pickedRole.name })
        .then(res => {
          if (res?.success) {
            const roles = res.content as IClientPortalRole[];

            const activeRole = roles.find(role => role.name === pickedRole.name);

            this.setState({ roles, activeRole });
          }
        });
    } else {
      const { roles } = this.state;
      this.setState({ activeRole: roles.find(role => role.id === Number(pickedRole.id)) });
    }
  };

  togglePermissions(roleId: number, data: { menu: string, permission: string, enabled: boolean }) {
    const token = `${data.menu}-${data.permission}`;

    APIClass.SettingsEndpoint.UpdatePortalRolePermissions(roleId, data)
      .then(res => {
        if (res?.success) {
          const updatedRole = res.content as IClientPortalRole;

          const activeRole: IClientPortalRole = updatedRole;

          const { roles } = this.state;

          const index = roles.findIndex(role => role.id === updatedRole.id);

          roles.splice(index, 1, updatedRole);

          this.setState({ roles, activeRole });
        }
        const { loadingPermissions } = this.state;
        const index = loadingPermissions.findIndex(lp => lp === token);
        if (index >= 0) {
          loadingPermissions.splice(index, 1);
          this.setState({ loadingPermissions });
        }
      });

  }

  onPermissionToggle(menu: string, permission: string, enabled: boolean) {
    const token = `${menu}-${permission}`;

    const { loadingPermissions, activeRole } = this.state;

    const index = loadingPermissions.findIndex(mp => mp === token);
    if (index === -1) {
      loadingPermissions.push(token);
      this.setState({ loadingPermissions }, () => {
        this.togglePermissions(activeRole!.id, { menu, permission, enabled });
      });
    }
  };

  onIsAdminChange = (value: boolean) => {
    const { activeRole } = this.state;
    APIClass.SettingsEndpoint.UpdatePortalRole(activeRole!.id, { is_admin: value })
      .then(res => {
        if (res?.success) {
          this.setState({ activeRole: res.content as IClientPortalRole });
        }
      });
  };

  render() {
    const { loaded, roles, loadingPermissions, activeRole } = this.state;

    if (!loaded) {
      return (
        <div className={css.center}>
          <Loader.Spinner/>
        </div>
      );
    }

    return (
      <div className={css.accessRightsRoot}>
        <div className={css.role}>
          <Heading className={css.roleHeading} variant="h2" title={t.SELECT_ROLE}/>
          <Input.Select
            bCreatable
            placeholder="Search or create role by typing"
            options={roles.map(role => ({ id: String(role.id), name: role.name }))}
            defaultValue={activeRole ? ({ id: String(activeRole.id), name: activeRole.name }) : undefined}
            onChange={this.handleRoleChange}
          />
          {activeRole ? (
            <div style={{margin: '20px 0 40px'}}>
              <Input.Switch label="Admin role" bChecked={activeRole.is_admin} onChange={this.onIsAdminChange}/>
            </div>
          ) : null}
        </div>
        <hr />
        <Heading className={css.permissionsHeading} variant="h2" title={t.PERMISSIONS}/>
        <div className={css.bottomFlex}>
          {activeRole?.access?.map(module => (
            <div key={module.name}>
              <Card title={module.name} titleClassName={css.permissionsTitle}>
                <List
                  item={permission => (
                    <div className={css.listRow}>
                      {permission!.name}
                      {!loadingPermissions.includes(`${module.name}-${permission!.token}`) ? (
                        <Input.Switch bDisabled={permission!.has_permission.from !== null} bChecked={permission!.has_permission.granted} onChange={value => this.onPermissionToggle(module.name, permission!.token, value)}/>
                      ) : (<Loader.Spinner size={14}/>)}
                    </div>
                  )}
                  collection={module.permissions}
                />
              </Card>
            </div>
          ))}
        </div>
      </div>
    );
  }

}

