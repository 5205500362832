// API
import API from 'api'
import { observable } from 'mobx'

interface IStringEntry {
  id: number
  type: string
  name: string
  slug: string
}

class DownloadsServiceClass {
  @observable
  strings: IStringEntry[] = []
  @observable
  isDownloadPage: boolean = false

  loadStrings() {
    API.Downloadv2Endpoint.getStrings().then(res => {
      if (res?.success) {
        this.strings = res.content as IStringEntry[]
      }
    })
  }

  getName = (needle: string) => {
    const found = this.strings.findIndex(s => s.slug === needle)
    if (found === -1) {
      return needle
    }
    return this.strings[found].name
  }

  setDownloadPage = (needle: boolean) => {
    this.isDownloadPage = needle
  }

}

export const DownloadsService = new DownloadsServiceClass()
