// language translations
import { en } from './en'
// libraries
import I18n from 'i18n-js'

export const t = en

// export default t

// I18n = {} // You must define this object in top namespace, which should be `window`
I18n.defaultLocale = 'en'
I18n.locale = 'en'

// Load I18n from `i18n.js`, `application.js` or whatever

I18n.currentLocale()
// pt-BR
