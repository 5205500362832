// core
import React, { Fragment, ReactElement, ReactNode, useRef, useState, useEffect } from 'react'

// libraries
import classnames from 'classnames'

// components
import { IComponentProps } from 'components'
import { Icon, IIconProps } from 'components/basic/Icon/Icon'

// style
import css from './Breadcrumbs.module.scss'

// partials
import { Breadcrumb } from './Partials/Breadcrumb'

export type BreadcrumbsItemType = {
  /**
   * Title to display
   */
  title: string | ReactElement<IIconProps>
  /**
   * Path - if provided it will renter link
   */
  path?: string
  /**
   * Callback to run on item click
   */
  onClick?: () => void
}

export interface IBreadcrumbsProps extends IComponentProps {
  /**
   * List of items
   */
  items: BreadcrumbsItemType[]
  /**
   * Custom separator
   */
  separator?: ReactNode
}

export const Breadcrumbs = ({ items, separator, className }: IBreadcrumbsProps) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const [fits, setFits] = useState(true)
  const [scrollable, setScrollable] = useState(false)

  useEffect(() => {
    if ((rootRef.current?.clientHeight || 0) > 25) {
      setFits(false);
    } else if (fits) {
      setFits(true);
      setScrollable(false);
    }
  }, [items, fits])

  const sep = separator ? (
    <div className={css.separator}>{separator}</div>
  ) : (
    <Icon className={css.separator} name="keyboard_arrow_right" />
  )

  return (
    <div
      ref={rootRef}
      className={classnames(css.root, { [css.horisontalScroll]: scrollable }, className)}>
      {fits &&
        items.map((item, key) => {
          return (
            <Fragment key={typeof item.title === 'string' ? item.title : key}>
              <Breadcrumb item={item} />

              {key < items.length - 1 ? sep : null}
            </Fragment>
          )
        })}
      {!fits && (
        <>
          <Breadcrumb item={items[0]} />

          {sep}

          <Breadcrumb item={{
            title: '...',
            onClick() {
              setFits(true)
              setScrollable(true)
            }
          }} />

          {sep}

          <Breadcrumb item={items[items.length - 1]} />
        </>
      )}
    </div>
  )
}
