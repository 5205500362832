// core
import React from 'react'
// import cx from 'classnames';

// styles
import css from '../Downloads.module.scss'

// components
import { TableVirtualized } from 'components'
import { GeneralCell, listWsABVersion } from './data'

export const WebswingVersionABTable = () => {
  return (
    <TableVirtualized
      className={css.aBTable}
      rowClassName={css.fontSmall}
      collection={listWsABVersion}
      rowHeight={45}
      headerHeight={45}
      headerClass={css.headerTable}
      minTableWidth={1400}
      columns={[
        {
          dataKey: 'version',
          label: 'Webswing version (A.B)',
          minWidth: 160,
          component: GeneralCell,
        },
        {
          dataKey: '212',
          label: '21.2',
          minWidth: 85,
          component: GeneralCell,
        },
        {
          dataKey: '221lts',
          label: '22.1 (LTS)',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: '222',
          label: '22.2',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: '231',
          label: '23.1',
          minWidth: 90,
          component: GeneralCell,
        },
        {
          dataKey: '232lts',
          label: '23.2 (LTS)',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: '241',
          label: '24.1',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: '242',
          label: '24.2',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: '251lts',
          label: '25.1 (LTS)',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: '252',
          label: '25.2',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: '261',
          label: '26.1',
          minWidth: 50,
          component: GeneralCell,
        },
      ]}
    />
  )
}
