// core
import React, { createElement, ReactElement } from 'react'

// libraries
import classnames from 'classnames'

// style
import css from './Heading.module.scss'
import { IComponentProps } from 'components'
import { IButtonDefaultProps } from 'components/basic/Button/variants/ButtonDefault/ButtonDefault'

export interface IHeadingInterface extends IComponentProps {
  /**
   * title to display
   */
  title: string
  rightTitle?: string
  /**
   * Buttons to render above the card
   */
  buttons?: (ReactElement<IButtonDefaultProps> | null)[]
  /**
   * variant of heading
   */
  variant?: 'h1' | 'h2' | 'h3'
  onClick?: () => void
}

export const Heading = ({
  buttons,
  title,
  rightTitle,
  variant = 'h1',
  className,
  classes = {},
  onClick,
}: IHeadingInterface) => {
  return (
    <div className={classnames(css.root, className, classes.root)} onClick={onClick}>
      <div>
        {createElement(variant, {
          children: title,
          className: classnames(css.heading, css[variant], classes.heading),
        })}
      </div>
      <div className={css.rightFlex}>
        {rightTitle &&
          createElement(variant, {
            children: rightTitle,
            className: classnames(css.heading, css[variant], classes.heading, css.rightTitle),
          })}
        {buttons && buttons.length > 0 ? (
          <div className={classnames(css.buttonsWrap, classes.buttonsWrap)}>{buttons}</div>
        ) : null}
      </div>
    </div>
  )
}
