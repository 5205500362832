// core
import React from 'react'

// libraries
import { Link } from 'react-router-dom'

// logo
import WebswingLogo from 'assets/images/webswing basic inverse.svg'

export const Logo = () => {
  return (
    <Link to="/dashboard">
      <img src={WebswingLogo} alt="logo" />
    </Link>
  )
}
