// core
import React, { useEffect, useState } from 'react';

// components
import { Card, CardContent, formatDate, Heading } from 'components';

// translation
import { t } from 'i18n';
import css from '../Dashboard.module.scss';
import API from 'api';

interface IDateValue {
  at: string | number
  from: string | null
}

export interface IStatistic {
  label: string
  value: string | number | IDateValue
  date?: boolean
}

const Statistics = () => {
  const [stats, setStats] = useState<IStatistic[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      API.DashboardEndpoint.Statistics().then(res => {
        if (res?.success) {
          setStats(res.content as IStatistic[]);
          setLoaded(true);
        }
      });
    }
  }, [loaded]);
  return (
    <div>
      <Heading variant="h3" title={t.STATISTICS}/>
      <div className={css.statisticsDiv}>
        <div className={css.statisticsRow}>
          {stats.map(stat => (
            <Card key={stat.label}>
              <CardContent>
                <div className={css.label}>{stat.label}</div>
                <div className={css.text}>{stat.date ? formatDate((stat.value as IDateValue).at, {fallback: 'never'}) : (stat.value || 'N/A')}</div>
                <div className={css.by}>{(stat.value as IDateValue).from ? `by ${(stat.value as IDateValue).from}` : ''}</div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
