// core
import React from 'react';

// components
import { Button } from '../../../components/basic';
import { Facebook, Github, Google, Linkedin } from '@icons-pack/react-simple-icons';

// styles
import css from './SocialAuthButtons.module.scss'

// services
import { AuthService } from '../../../services/AuthService';

export const SocialAuthButtons = () => {
  const { socialLoginUrl } = AuthService
  return (
    <Button.Group
      buttons={[
        { size: 'large', className: css.btnGoogle, icon: <Google color='#ffffff'/>, onClick() {socialLoginUrl('google');} },
        { size: 'large', className: css.btnLinkedin, icon: <Linkedin color='#ffffff'/>, onClick() {socialLoginUrl('linkedin');} },
        { size: 'large', className: css.btnGithub, icon: <Github color='#ffffff'/>, onClick() {socialLoginUrl('github');} },
      ]}
    />
  )
}
