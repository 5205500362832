// core
import React from 'react'

// libraries
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'

// components
import { Heading } from 'components/complex/Heading/Heading'
import { IComponentProps, Button } from 'components'

// style
import css from './PageHeader.module.scss'
// import { UserAvatar } from 'components/layout/UserAvatar/UserAvatar'

export interface IPageHeaderInterface extends IComponentProps {
  /**
   * title to display
   */
  title: string
  /**
   * path of back button
   */
  back?: string
}

export const PageHeader = ({ title, back, className, classes = {} }: IPageHeaderInterface) => {
  const history = useHistory()

  const handleBackButtonClick = () => {
    history.push(back || '/')
  }

  return (
    <div className={classnames(css.root, className, classes.root)}>
      <div className={css.pageHeaderTitle}>
        {back && <Button color="neutral" icon="chevron_left" onClick={handleBackButtonClick} />}
        <Heading classes={{ heading: classes.heading, root: classes.headingRoot }} title={title} />
      </div>
      {/* <UserAvatar /> */}
    </div>
  )
}
