// .core
import React, { useEffect, useState } from 'react';
// components
import { Button, Card, Heading, Input, Loader, showToastOk } from 'components';
// i18n
import { t } from 'i18n'
// styles
import css from './ForgottenPasswordPage.module.scss';
import { Logo } from '../Partials/Logo';
import Image from 'assets/images/image.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Field, Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { ForgottenPasswordForm, IForgottenPasswordForm } from '../../../utils';
import { AuthService } from '../../../services/AuthService';
import { changeTitle } from '../../routes';
import APIClass from '../../../api';
import Footer from '../Partials/Footer';

const forgottenPasswordForm = new ForgottenPasswordForm();

export const ForgottenPasswordPage = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const [message, setMessage] = useState<string>();
  const [sentResetEmail, setSentResetEmail] = useState<boolean>(false);
  const [checkForResetToken, setCheckForResetToken] = useState<boolean>(false);
  const [resettingPassword, setResetingPassword] = useState<boolean>(false);
  const [forgottenPasswordDefault, setForgottenPasswordDefault] = useState<IForgottenPasswordForm>({ email: '' });

  useEffect(() => {
    if (!checkForResetToken) {
      if (location.search.includes('token=')) {
        const params = new URLSearchParams(window.location.search.substr(1));
        const resetToken = params.get('token');
        if (resetToken) {
          setResetingPassword(true);
          setForgottenPasswordDefault({
            token: params.get('token')!,
            email: params.get('email') || '',
            password: '',
          });
          setCheckForResetToken(true)
        }
      } else {
        setCheckForResetToken(true);
      }

    }
  }, [checkForResetToken, location]);

  resettingPassword ? changeTitle('Reset Password') : changeTitle('Forgotten Password');

  const onSubmit = (values: IForgottenPasswordForm, { setErrors }: FormikHelpers<IForgottenPasswordForm>) => {
    if (resettingPassword) {
      AuthService.resetPassword(values).then(message => {
        history.push('/dashboard');
        AuthService.checkUser().then(() => {
          showToastOk(message);
        });
      }).catch(res => {
        setErrors(res.content?.errors || []);
      });
    } else {
      AuthService.toggleSubmitting()
      APIClass.AuthEndpoint.ForgottenPassword(values).then(res => {
        if (res?.success) {
          setMessage(res.message);
          setSentResetEmail(true);
        } else {
            setErrors((res as any).content.errors);
        }
        AuthService.toggleSubmitting()
      });
    }
  };

  function preventDefault(e: React.FormEvent) {
    e.preventDefault();
  }

  const { formSubmitting } = AuthService;

  return (
    <div className={css.root}>
      <div className={css.logoFlex}>
        <Logo/>

        <Link to="/login">
          <Button size="small" color="neutral" label="Log in"/>
        </Link>
      </div>
      <Card className={css.card} classes={{ content: css.cardFlex }}>
        <img src={Image} alt="leftImage"/>
        {checkForResetToken ? (
          <div className={css.registerContent}>
            <Heading title="Reset your password"/>

            {resettingPassword ? (
              <p>Pick a new password for account <strong>{forgottenPasswordDefault.email}</strong></p>
            ) : (
              <p>Enter the email you used to login in to the system</p>
            )}

            {sentResetEmail ? null : (
              <Formik
                initialValues={forgottenPasswordDefault}
                validationSchema={resettingPassword ? forgottenPasswordForm.VALIDATION_RESET : forgottenPasswordForm.VALIDATION_INIT}
                onSubmit={onSubmit}
              >
                {(form: FormikProps<FormikValues>) => (
                  <Form onSubmit={preventDefault}>
                    {resettingPassword ? (<Field type="hidden" name="token"/>) : null}
                      <Input.Field
                      className={css.input}
                      label="Email"
                      name="email"
                      type={resettingPassword ? 'hidden' : 'email'}
                      placeholder="Enter your email"
                      bAutoFocus={!resettingPassword}
                    />
                    {resettingPassword ? (
                      <Input.Field
                        className={css.input}
                        label="Password"
                        name="password"
                        type="password"
                        placeholder="Enter new password"
                        bAutoFocus={resettingPassword}
                      />
                    ) : null}
                    <Button type="submit" className={css.registerButton} label={resettingPassword ? t.RESET_PASSWORD : t.SEND_RESET_EMAIL} onClick={form.submitForm}/>
                    {formSubmitting && <Loader className={css.spinner} size={15}/>}
                  </Form>
                )}
              </Formik>
            )}
            {message ? (<div className={css.success}>{message}</div>) : null}
          </div>
        ) : (<div className={css.centerMiddle}><Loader.Spinner/></div>)}
      </Card>
      <Footer center />
    </div>
  );
});
