// .core
import React from 'react'
// components
import { NoData } from 'components'
// i18n
import { t } from 'i18n'
// libraries
import { Route } from 'react-router-dom'
// styles
import css from './PageNotFound.module.scss'

/**
 * Wrapper for 404 not found page
 */
export const PageNotFound = () => (
  <Route path="*">
    <div className={css.wPageNotFound}>
      <NoData message={t.TOAST_404} />
    </div>
  </Route>
)
