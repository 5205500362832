// .core
import React from 'react'
// components
import { ISkeletonProps } from './SkeletonUI'
// libraries
import cx from 'classnames'
// styles
import css from '../Skeleton.module.scss'

interface ISkeletonCircleProps extends ISkeletonProps {}

// #TODO
export const SkeletonCircle: React.FC<ISkeletonCircleProps> = ({
  className
}: ISkeletonCircleProps) => <div className={cx(css.wSkeletonCircle, className)} />
