/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/promise-function-async */
// core
import React, { useCallback, useEffect, useState } from 'react'
import XMLViewer from 'react-xml-viewer'
// import cx from 'classnames';

// styles
import css from './Downloads.module.scss'

// components
import {Button, Card, CardContent, Heading, Icon, Loader, Tab, Tabs} from 'components'

// translation
import { t } from 'i18n'

import { changeTitle } from '../../routes'
import APIClass from 'api'
import { useHistory } from 'react-router-dom'
import { ContentLocked } from './Partials/ContentLocked'
// import { CopyBlock, dracula } from "react-code-blocks";
// services
import { AuthService } from '../../../services/AuthService'
import { observer } from 'mobx-react'
import { IUser } from 'api/interfaces/IUser'
import { ICompany } from 'api/interfaces/ICompany'
import { DownloadsService } from 'services/DownloadsService'
interface IRepoEntry {
  filename: string
  link: string
  is_preview: boolean
}

export const Repos = observer(() => {
  const [loading, setLoading] = useState<boolean>(false)
  const [coppied, setCoppied] = useState<boolean>(false)
  const [copySetting, setCopySetting] = useState<boolean>(false)
  const [list, setList] = useState<IRepoEntry[]>([])
  const history = useHistory()
  const [activeTab, setActiveTab] = useState<number>(0)

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      if (activeTab !== tabIndex) {
        setActiveTab(tabIndex)
      }
    },
    [activeTab]
  )

  changeTitle(t.REPOS)

  const loadFolder = useCallback(() => {
    setLoading(true)
    APIClass.Downloadv2Endpoint.repoList().then(res => {
      if (res?.status === 403) {
        history.push('/dashboard')
      }
      if (res?.success) {
        setList(res.content as IRepoEntry[])
      }
      setLoading(false)
    })
  }, [history])

  useEffect(() => {
      if (!AuthService.currentUser?.company?.is_not_payed) {
          loadFolder()
      }
  }, [])

  const getSnippet = (id: string, url: string) => {
    const xml = ` <repositories>
    <repository>
      <id>${id}</id>
      <url>${url}</url>
    </repository>
  </repositories>`
    return xml
  }

    const getSettingsSnippet = (id: string, url: string) => {
        const xml = ` <settings>
      <profiles>
        <profile>
          <id>webswing</id>
          <activation>
            <activeByDefault>true</activeByDefault>
          </activation>
          <repositories>
            <repository>
              <id>${id}</id>
              <url>${url}</url>
            </repository>
          </repositories>
        </profile>
        ...
      </profiles>
  </settings>`
        return xml
  }

  const customTheme = {
    attributeKeyColor: '#FF0000',
    attributeValueColor: '#000FF',
  }

    if (AuthService.currentUser?.company?.is_not_payed) {
        return (
            <div className={css.contentLocked}>
                <Icon size="extraLarge" color="blue" name="info" />
                <h1>Your access to repositories was deactivated.</h1>
                {/*<Button label="Activate" color="blue" onClick={} />*/}
                <p>
                    Reason of deactivation is probably the License expiration.
                    Please contact our Sales support <a href="sales@webswing.org">sales@webswing.org</a> in order to reactivate your access.
                </p>
            </div>
        )
    }

  if (loading) {
    return (
      <div className={css.full}>
        <Loader />
      </div>
    )
  }

  const coppyContent = (item: IRepoEntry, settings?: boolean) => {
      if (settings) {
          if (copySetting) {
              navigator.clipboard.writeText('')
              setCopySetting(false)
          } else {
              navigator.clipboard.writeText(getSettingsSnippet(item.filename, item.link))
              setCopySetting(b => !b)
              if(coppied) setCoppied(false)
          }
      } else {
          if (coppied) {
              navigator.clipboard.writeText('')
              setCoppied(false)
          } else {
              navigator.clipboard.writeText(getSnippet(item.filename, item.link))
              setCoppied(b => !b)
              if(copySetting) setCopySetting(false)
          }
      }
  }

  const updateCompany = () => {
    const user = AuthService.currentUser as IUser
    const body = { ...user.company, is_token_locked: !user?.company_locked_token }
    return APIClass.Downloadv2Endpoint.UpdateCompanyTokenLock(
      user.company_id,
      body as ICompany
    ).then(res => {
      if (res?.success) {
        AuthService.updateLockedToken((res.content as any).is_token_locked)
      }
      return res
    })
  }

  return (
    <>
      <Card title="Maven" className={css.marginBottom}>
        <CardContent>
          {AuthService.currentUser?.company_locked_token && AuthService.currentUser?.company_id && list.length ? (
            <Tabs defaultTab={activeTab} onChangeTab={handleTabChange}>
              {list
                .filter(i => !i.is_preview && i.filename.substring(0, 8) === 'webswing')
                .map(item => (
                  <Tab
                    key={item.filename}
                    label={DownloadsService.getName(item.filename)}
                    className={css.tabNoOverflow}>
                    <div className={css.space}>
                        <Heading
                            title="Pom.xml :"
                            variant="h3"
                            buttons={[
                                <Button
                                    key="copyButton"
                                    iconColor={coppied ? 'white' : undefined}
                                    color={coppied ? 'blue' : 'neutral'}
                                    size="small"
                                    icon="content_copy"
                                    onClick={() => coppyContent(item, false)}
                                />,
                            ]}
                        />
                        <XMLViewer
                            className={css.viewerStyle}
                            xml={getSnippet(item.filename, item.link)}
                            theme={customTheme}
                        />
                    </div>
                   {
                      item.filename.includes('3rd-parties') && (
                           <div className={css.space}>
                               <Heading
                                   title="Settings.xml :"
                                   variant="h3"
                                   buttons={[
                                       <Button
                                           key="copyButton"
                                           iconColor={copySetting ? 'white' : undefined}
                                           color={copySetting ? 'blue' : 'neutral'}
                                           size="small"
                                           icon="content_copy"
                                           onClick={() => coppyContent(item, true)}
                                       />,
                                   ]}
                               />
                               <XMLViewer
                                   className={css.viewerStyle}
                                   xml={getSettingsSnippet(item.filename, item.link)}
                                   theme={customTheme}
                               />
                           </div>
                       )
                   }
                  </Tab>
                ))}
            </Tabs>
          ) : AuthService.currentUser?.company_id ? (
            <ContentLocked
              isKeyPerson={AuthService.currentUser?.is_key_person}
              onClick={updateCompany}
            />
            ) :
            <ContentLocked>
              <h1>Sorry, you don't have access to repositories.</h1>
              <p>
                To get access please contact <a href="sales@webswing.org">sales@webswing.org</a>
              </p>
            </ContentLocked>


        }
        </CardContent>
      </Card>

      {/* <Card title='Docker' className={css.marginBottom}>
        <CardContent>
          //
        </CardContent>
      </Card> */}
    </>
  )
})
