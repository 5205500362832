// core
import React, { MouseEvent } from 'react'

// data types
import { BreadcrumbsItemType } from 'components'

// components
import { Link } from 'react-router-dom'

// utils
import { runCallback } from 'utils/functions'

// style
import css from '../Breadcrumbs.module.scss'

export interface IBreadcrumbProps {
  /**
   * List of items
   */
  item: BreadcrumbsItemType
}

export const Breadcrumb = ({ item }: IBreadcrumbProps) => {
  return item.path ? (
    <Link className={css.item} to={item.path}>
      {item.title}
    </Link>
  ) : item.onClick ? (
    // eslint-disable-next-line
    <a
      className={css.item}
      href="#"
      // eslint-disable-next-line react/jsx-no-bind
      onClick={(event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault()
        event.stopPropagation()

        runCallback(item.onClick)
      }}>
      {item.title}
    </a>
  ) : (
    <div className={css.item}>{item.title}</div>
  )
}
