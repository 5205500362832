/* eslint-disable react/jsx-no-useless-fragment */
// core
import React, { useCallback, useState } from 'react'

// styles
import css from '../Downloads.module.scss'

// components
import { Tab, Tabs } from 'components'

// translation
import { FilesTable, IFilesTableEntry } from './FilesTable'
import { DownloadsService } from 'services/DownloadsService'
import { observer } from 'mobx-react'

interface IFilesTabsProps {
  files: IFilesTableEntry[] | undefined
  tabs: string[]
  loading: boolean
}

export const FilesTabs = observer(({ files, tabs, loading }: IFilesTabsProps) => {
  // const tabMap = tabs;
  //const index = tabMap.findIndex(i => i === tab)

  const [activeTab, setActiveTab] = useState<number>(0)

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      if (activeTab !== tabIndex) {
        setActiveTab(tabIndex)
      }
    },
    [activeTab]
  )
  
  const getCollection = useCallback(
    (tab: string) => {
      if ( tab === 'eval' ) {
        return files?.filter(file => file.versionType === tab)
      }
      
      return files?.filter(file => file.fileType === tab && file.versionType !== 'eval')
    },
    [files]
  )

  return (
    <>
      {tabs.length && (
        <Tabs defaultTab={activeTab} onChangeTab={handleTabChange}>
          {tabs.map(tab => (
            <Tab key={tab} label={DownloadsService.getName(tab)} className={css.tabNoOverflow}>
              <FilesTable
                collection={getCollection(tab)}
                loading={loading}
              />
            </Tab>
          ))}
        </Tabs>
      )}
    </>
  )
})
