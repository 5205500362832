// .core
import React from 'react'
// components
import { ISkeletonProps } from './SkeletonUI'
// libraries
import cx from 'classnames'
// styles
import css from '../Skeleton.module.scss'

export interface ISkeletonTableProps extends Omit<ISkeletonProps, 'children'> {
  borderRadius?: number
  /**
   * how many skeleton rows to display
   */
  count?: number
  rowHeight?: number
}

export const SkeletonTable: React.FC<ISkeletonTableProps> = (props: ISkeletonTableProps) => {
  const { count, rowHeight, className, borderRadius, style } = props

  const items = Array(count || 5).fill(1)

  const styles = { height: rowHeight, borderRadius: borderRadius }

  return (
    <div style={style} className={cx(css.wSkeletonTable, className)}>
      {items.map((_, index) => (
        <div
          key={index}
          className={cx(css.item, css.itemAnimation)}
          style={{ ...styles, animationDelay: `${0.05 * index}s` }}
        />
      ))}
    </div>
  )
}
