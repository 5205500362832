// API
import API from 'api'
// libs
import { action, observable } from 'mobx'
// utils
import {
  IForgottenPasswordForm,
  ILoginForm,
  IRegistrationForm,
} from 'utils/forms/AuthentificationForms'
import { IUser } from '../api/interfaces/IUser'

interface IAccessTokenResponse {
  access_token: string
  docs?: string
  _idl?: boolean
}

class AuthServiceClass {
  @observable
  currentUser?: IUser

  @observable
  checkingForUser: boolean = false

  @observable
  formSubmitting: boolean = false

  @action
  toggleSubmitting = () => {
    this.formSubmitting = !this.formSubmitting
  }

  @action
  updateLockedToken = (isLocked: boolean) => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.currentUser!.company_locked_token = isLocked
  }

  get bIsLoggedIn(): boolean {
    return !!this.currentUser
  }

  public hasPermission = (moduleName: string, permissionName: string): boolean => {
    if (!this.bIsLoggedIn) {
      return false
    }

    const module = this.currentUser!.access?.find(module => module.name === moduleName)

    if (!module) {
      return false
    }

    return !!module.permissions.find(permission => permission.token === permissionName)
  }

  /** refresh user info */
  async refreshUserInfo(): Promise<any> {
    return API.AuthEndpoint.User().then(res => {
      if (res?.success) {
        this.currentUser = res.content as IUser
      }
    })
  }

  /**
   * Checks the sessionStorage for saved data about currently logged in user
   *
   * if it exists - save it to local var
   * if not - return
   */
  async checkUser(): Promise<any> {
    // const savedStorageData = localStorage.getItem('currentUser')
    // savedStorageData && (this.currentUser = JSON.parse(savedStorageData))
    const accessToken = sessionStorage.getItem('accessToken')
    if (accessToken) {
      this.checkingForUser = true
      return API.AuthEndpoint.User().then(res => {
        if (res?.success) {
          this.currentUser = res.content as IUser
        }

        this.checkingForUser = false

        return res
      })
    }
    this.checkingForUser = true

    return API.AuthEndpoint.Cookie().then(res => {
      this.checkingForUser = false

      if (res?.success) {
        sessionStorage.setItem('accessToken', (res?.content as IAccessTokenResponse).access_token)
        return this.checkUser()
      }

      return Promise.resolve()
    })
  }

  /**
   * Deletes the account of currently signed in user from the DB
   */
  async deleteAccount() {
    await API.AuthEndpoint.DeleteAccount()
  }

  kickUser() {
    if (sessionStorage.getItem('accessToken')) {
      sessionStorage.removeItem('accessToken')
      // useHistory().push('/')
    }
  }

  async socialLoginUrl(driver: string) {
    const res = await API.AuthEndpoint.SocialLoginUrl(driver)

    window.location.href = res?.content as string
  }

  async socialLogin(driver: string) {
    const res = await API.AuthEndpoint.SocialLogin(driver)

    if (res?.success) {
      sessionStorage.setItem('accessToken', (res?.content as IAccessTokenResponse).access_token)
    }

    return res?.success
      ? Promise.resolve({ ...(res?.content as IAccessTokenResponse), message: res.message })
      : Promise.reject({ message: res?.message })
  }

  /**
   * Logs a user in based on provided credentials and saves the BE response into the `sessionStorage`
   * @param credentials user-entered credential information (e.g.: email and password)
   */
  async logIn(credentials: ILoginForm) {
    this.toggleSubmitting()
    const res = await API.AuthEndpoint.Login(credentials)
    this.toggleSubmitting()

    if (res?.success) {
      sessionStorage.setItem('accessToken', (res?.content as IAccessTokenResponse).access_token)
    }

    return res?.success ? Promise.resolve(res.message) : Promise.reject(res?.message)
  }

  /**
   * Logs out the currently signe in user and clears the entire `sessionStorage`
   */
  logOut() {
    API.AuthEndpoint.Logout().then(() => {
      sessionStorage.removeItem('accessToken')
      this.currentUser = undefined
    })
  }

  /**
   * Registers a user into the DB based on provided credential info
   * @param credentials user-entered credential information (e.g.: name, age, email and password)
   */
  async register(credentials: IRegistrationForm) {
    this.toggleSubmitting()
    const res = await API.AuthEndpoint.Register(credentials)
    this.toggleSubmitting()

    if (res?.success) {
      sessionStorage.setItem('accessToken', (res?.content as IAccessTokenResponse).access_token)
    }
    return res?.success ? Promise.resolve(res.message) : Promise.reject(res?.message)
  }

  async resetPassword(credentials: IForgottenPasswordForm) {
    this.toggleSubmitting()
    const res = await API.AuthEndpoint.ResetPassword(credentials)
    this.toggleSubmitting()

    if (res?.success) {
      sessionStorage.setItem('accessToken', (res?.content as IAccessTokenResponse).access_token)
    }
    return res?.success ? Promise.resolve(res.message) : Promise.reject(res)
  }
}

export const AuthService = new AuthServiceClass()
