// .core
import React from 'react'
// libraries
import cx from 'classnames'
// styles
import css from './Radio.module.scss'

interface IRadioProps {
  bChecked?: boolean
  bDisabled?: boolean

  className?: string
  classNameLabel?: string
  classNameRadio?: string

  id?: string
  label?: string | React.ReactNode
  name?: string

  onChange?(value: boolean): void
}

/**
 * `Radio` variant of the default HTML input
 */
export const Radio = ({
  bChecked,
  bDisabled,
  className,
  classNameLabel,
  classNameRadio,
  id,
  label,
  name,
  onChange
}: IRadioProps) => {
  const _onChange = () => {
    !bDisabled && onChange && onChange(!bChecked)
  }

  return (
    <div className={cx(css.wRadio, className)} onClick={_onChange}>
      {/* RADIO INPUT */}
      <input
        type="radio"
        checked={bChecked}
        className={cx(classNameRadio)}
        disabled={bDisabled}
        id={id || name}
        name={name}
      />

      {/* LABEL */}
      {label && (
        <label htmlFor={id || name} className={cx(classNameLabel)}>
          {label}
        </label>
      )}
    </div>
  )
}
