// .core
import React, { useEffect } from 'react';
// components
import { InputDefault, IInputDefaultProps } from '../InputDefault/InputDefault';
// libraries
// import cx from 'classnames'
import { ErrorMessage, Field, FieldProps, useFormikContext } from 'formik';
// styles
import css from './InputField.module.scss';
import { Input } from '../../Input';
import { ISelectItem } from '../../../../../utils';

interface IInputFieldProps extends Omit<IInputDefaultProps, 'value'> {
  name: string
  autoSubmit?: boolean
  type?: 'email' | 'text' | 'number' | 'password' | 'checkbox' | 'select' | 'hidden'
}

/**
 * Formik's `<Field />` wrapper to our `<InputDefault />`
 *  - handles pairing `value, onChange, onBlur` based on the passed `name` value
 *  - handles displaying `errors` after validation based on the passed `name` value
 *
 * #TODO:
 *  - add support for Input variants
 *      - styling
 *      - binding value changes
 *      - etc.
 */
export const InputField = ({ name, autoSubmit = false, type = 'text', ...otherProps }: IInputFieldProps) => {
  const formik = useFormikContext()

  useEffect(() => {
    if (true === autoSubmit && formik.initialValues !== formik.values) {
      formik.submitForm()
    }
  }, [formik.values])

  const renderComponent = (fieldProps: FieldProps): React.ReactNode => {
    switch (type) {
      case 'select':
        const _onChange = (value: ISelectItem) => {
          fieldProps.form.setFieldValue(fieldProps.field.name, value)
        }
        return <Input.Select bClearable {...otherProps} {...fieldProps.field} onChange={_onChange} />;

      case 'checkbox':
        return <Input.Checkbox formik {...otherProps} {...fieldProps.field} />;

      case 'text':
      default:
        return <InputDefault type={type} {...otherProps} {...fieldProps.field} />;

    }
  };

  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <div className={css.wInputField}>
          {renderComponent(fieldProps)}
          <ErrorMessage component="span" name={name} className={css.errorMessage}/>
        </div>
      )}
    </Field>
  );
};
