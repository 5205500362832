// core
import React from 'react'

// libraries
import { Link } from 'react-router-dom'
import classnames from 'classnames'

// components
import { Icon } from 'components/basic'
import { IWrapperComponentProps } from 'components'

// styles
import css from '../MainMenu.module.scss'
import { IMenuItems } from '../data'

export interface IMenuItemProps extends IWrapperComponentProps {
  /**
   * link where will click redirect
   */
  path: string
  /**
   * icon to show
   */
  icon?: string
  /**
   * path that is currently active
   */
  activePath: string

  target?: string
  /**
   * callback after click
   */
  onClick: () => void

  subItems?: IMenuItems[]
}

export const MenuItem = ({
  children,
  path,
  icon,
  activePath,
  onClick,
  target,
  subItems,
}: IMenuItemProps) => {
  return (
    <li>
      {target ? (
        <a href={path} target={target} className={css.menuItem} onClick={onClick}>
          {icon && <Icon className={css.icon} name={icon} />}

          <div className={css.text}>{children}</div>
        </a>
      ) : (
        <>
          <Link
            className={classnames(css.menuItem, {
              [css.activeLink]: !subItems && activePath === `/${path}`,
            })}
            to={`/${path}`}
            onClick={onClick}>
            {icon && <Icon className={css.icon} name={icon} />}
            <div className={css.text}>{children}</div>
          </Link>
          {subItems ? (
            <ul className={classnames(css.menuItems, css.subItems)}>
              {subItems?.map(sub => (
                <MenuItem key={sub.path} activePath={activePath} path={sub.path} onClick={onClick}>
                  {sub.name}
                </MenuItem>
              ))}
            </ul>
          ) : null}
        </>
      )}
    </li>
  )
}
