// core
import React, { useCallback, useEffect, useState } from 'react'

// styles
import css from './Downloads.module.scss'

// components
import { PageHeader, Tab, Tabs } from 'components'

// translation
import { t } from 'i18n'

import { useHistory, useParams } from 'react-router-dom'
import { changeTitle } from '../../routes'
import { Dist } from './Dist'
import { Repos } from './Repos'
import { Plan } from './Plan'
import { DownloadsService } from 'services/DownloadsService'

export const Downloads = () => {
  // const [tab, setTab] = useState<'distributios' | 'repositories' | 'plan'>('distributios');
  // const [loading, setLoading] = useState<boolean>(false);
  // const [list, setList] = useState<IDownloadVersions[]>();
  // const history = useHistory();
  const map = ['distributios', 'repositories', 'plan']
  const { tab } = useParams<{ tab: 'distributios' | 'repositories' | 'plan' }>()
  const index = map.findIndex(i => i === tab)
  const [activeTab, setActiveTab] = useState<number>(index >= 0 && index < map.length ? index : 0)
  const history = useHistory()

  changeTitle('Downloads')

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      if (activeTab !== tabIndex) {
        history.push(`/downloads/${map[tabIndex]}`)
        setActiveTab(tabIndex)
      }
    },
    [history, activeTab, map]
  )

  useEffect(() => {
    DownloadsService.loadStrings()
    DownloadsService.setDownloadPage(true);

    return () => DownloadsService.setDownloadPage(false);

  }, [])

  return (
    <>
      <PageHeader title={t.DOWNLOADS} />
      <Tabs defaultTab={activeTab} onChangeTab={handleTabChange}>
        <Tab label="Distributions" className={css.tabNoOverflow}>
          <Dist />
        </Tab>
        <Tab label="Repositories">
          <Repos />
        </Tab>
        <Tab label="Release Plan">
          <Plan />
        </Tab>
      </Tabs>
    </>
  )
}
