// core
import React, { useState } from 'react';
import cx from 'classnames';

// components
import { Input, Button, Card, Loader } from 'components';

// translation
import { t } from 'i18n';

// styles
import css from '../Settings.module.scss';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { ChangePasswordForm, IChangePasswordForm } from '../../../../utils/forms/SettingsForms';
import APIClass from '../../../../api';

const changePasswordForm = new ChangePasswordForm();

const Password = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState<IChangePasswordForm>(changePasswordForm.INITIAL_VALUES);

  const changePassword = (values: IChangePasswordForm, formikHelpers: FormikHelpers<IChangePasswordForm>) => {
    setLoading(true);
    APIClass.SettingsEndpoint.ChangePassword(values).then(res => {
      if (res?.success) {
        setValues(changePasswordForm.INITIAL_VALUES);
        formikHelpers.resetForm()
      }
      setLoading(false);
    });
  };

  return (
    <div className={cx(css.profileCards, css.pwd)}>
      <div>
        <Card title={t.CHANGE_PASSWORD}>
          <Formik
            enableReinitialize
            initialValues={values}
            validationSchema={changePasswordForm.VALIDATION}
            onSubmit={changePassword}
          >
            {(form: FormikProps<FormikValues>) => (
              <div className={css.fields}>
                <Input.Field
                  className={css.password}
                  label={t.OLD_PASSWORD}
                  name="old_password"
                  type="password"
                  placeholder="Enter your old password"
                />
                <Input.Field
                  className={css.password}
                  label={t.NEW_PASSWORD}
                  name="password"
                  type="password"
                  placeholder="Enter new password"
                />
                <Input.Field
                  className={css.password}
                  label={t.CONFIRM_PASSWORD}
                  name="password_confirmation"
                  type="password"
                  placeholder="Confirm new password"
                />
                <div>
                  <Button type="submit" label={t.CHANGE_PASSWORD} onClick={form.submitForm}/>
                  {loading && <Loader className={css.spinner} size={15}/>}
                </div>
              </div>
            )}
          </Formik>
        </Card>
      </div>
    </div>
  );
};

export default Password;
