// .core
import React from 'react'
//libs
import { Form, Formik, FormikProps, FormikValues } from 'formik'
import { Link } from 'react-router-dom'
// components
import { Button, Card, Heading, Input, Loader, showToastErr, showToastOk } from 'components'
import { Logo } from '../Partials/Logo'
import { SocialAuthButtons } from '../Partials/SocialAuthButtons'
import { ILoginForm, IPageProps, LoginForm } from 'utils'
// i18n
// import { t } from 'i18n'
// styles
import css from './LoginPage.module.scss'
import Image from 'assets/images/image.png'
import { AuthService } from '../../../services/AuthService'
import { observer } from 'mobx-react'
import { changeTitle } from '../../routes'
import Footer from '../Partials/Footer'
import ReCAPTCHA from "react-google-recaptcha";

@observer
export class LoginPage extends React.Component<IPageProps> {
  recapthaRef: any;
  loginForm = new LoginForm()
  
  constructor(props: IPageProps) {
    super(props);
    this.recapthaRef = React.createRef<ReCAPTCHA>();
  }


  componentDidMount() {
    changeTitle('Login')
    let driver = null
    if (window.location.pathname.includes('accept') && !window.location.search.includes('error=')) {
      const segments = window.location.pathname.split('/')

      driver = segments.pop()

      AuthService.socialLogin(driver as string).then(({ message, docs, _idl }) => {
        if (true === _idl) {
          window.location.href = docs!
          return
        }
        const { history } = this.props

        history.push('/dashboard', { state: { from: history.location } })

        AuthService.checkUser().then(() => {
          showToastOk(message)
        })
      })
    }
  }

  private login = async (values: ILoginForm) => {
    if (this.recapthaRef) {
      const token = await this.recapthaRef.current!.executeAsync();
      this.recapthaRef.current!.reset();
      if(token) {
          values.captha = token;
          AuthService.logIn(values).then(message => {
            const { history } = this.props
            history.push('/dashboard')
            AuthService.checkUser().then(() => {
              showToastOk(message)
            })
          })
          } else {
              showToastErr('reCAPTCHA verification failed!')
          }
      }
  }

  preventDefault(e: React.FormEvent) {
    e.preventDefault()
  }

  render() {
    const { formSubmitting } = AuthService

    return (
      <div className={css.root}>
        <div className={css.logoFlex}>
          <Logo />
          <Link to="/register">
            <Button size="small" color="neutral" label="Registration" />
          </Link>
        </div>
        <Card className={css.card} classes={{ content: css.cardFlex }}>
          <img src={Image} alt="leftImage" />
          <div className={css.loginContent}>
            <Heading title="Login" />
            <p>You can login via following services</p>
            <SocialAuthButtons />
            <p>or via your</p>
            <Formik
              initialValues={this.loginForm.INITIAL_VALUES}
              validationSchema={this.loginForm.VALIDATION}
              onSubmit={this.login}>
              {(form: FormikProps<FormikValues>) => (
                <Form onSubmit={this.preventDefault}>
                  <Input.Field
                    bAutoFocus
                    className={css.input}
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                  />
                  <Input.Field
                    className={css.input}
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                  />
                  <Input.Field
                    className={css.hide}
                    label="User"
                    name="user"
                    type="text"
                  />
                  <div className={css.checkbox}>
                    {/*<Input.Checkbox name="rememberMe" label="keep me logged in" bChecked={form.values.rememberMe} onChange={() => form.setFieldValue('rememberMe', !form.values.rememberMe)} />*/}
                    <Link to="/forgotten-password">Forgotten password</Link>
                  </div>
                  <Button
                    className={css.logInButton}
                    type="submit"
                    label="Log in"
                    onClick={form.submitForm}
                  />
                  {formSubmitting && <Loader className={css.spinner} size={15} />}
                </Form>
              )}
            </Formik>
            <ReCAPTCHA
                ref={this.recapthaRef}
                size="invisible"
                sitekey="6LdFosIkAAAAABAQ88_MBQRCmA81OHY7PzJ7QsYr"
            />
          </div>
        </Card>
        <Footer center />
      </div>
    )
  }
}
