// core
import React from 'react'

// logo
import { AuthService } from '../../../../services/AuthService';
import css from '../MainMenu.module.scss';
import { observer } from 'mobx-react';

export const CompanyLogo = observer(() => {
  if (AuthService.currentUser?.company?.logo) {
    return (
      <div className={css.companyLogo}>
        <img src={AuthService.currentUser.company.logo} alt={AuthService.currentUser.company.name}/>
      </div>
    )
  }

  return null
})
