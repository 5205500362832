// core
import React, { useCallback, useEffect, useState } from 'react';
import APIClass  from '../../../../../api';
import { Button, Card, Input, PageHeader } from 'components';
import { IClientPortalRole } from '../../../../../api/interfaces/IUser';
import { t } from 'i18n';
import css from '../../Settings.module.scss';
import { IInviteUserForm, InviteUserForm } from '../../../../../utils/forms/SettingsForms';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { useHistory } from 'react-router-dom';

const inviteForm = new InviteUserForm();

const Invite = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [accessRoles, setAccessRoles] = useState<IClientPortalRole[]>([]);
  const history = useHistory();

  const loadAccessRoles = () => {
    APIClass.SettingsEndpoint.RolesPortal().then(res => {
      if (res?.success) {
        const roles = res.content as IClientPortalRole[];
        setAccessRoles(roles);
      }
      setLoaded(true);
    });
  };

  useEffect(() => {
    if (!loaded) {
      loadAccessRoles();
    }
  }, [loaded]);


  const handleSubmit = useCallback((values: IInviteUserForm, { setErrors }: FormikHelpers<IInviteUserForm>) => {
    APIClass.SettingsEndpoint.InviteUser({
      ...values,
      // @ts-ignore
      client_portal_role_id: values.client_portal_role_id ? values.client_portal_role_id.id : null
    }).then(res => {
      if (res?.success) {
        history.push('/settings/management')
      } else {
        // @ts-ignore
        setErrors(res.content.errors)
      }
    })
  }, [history]);

  function preventDefault(e: React.FormEvent) {
    e.preventDefault();
  }

  return (
    <div>
      <PageHeader back="/settings/management" title="Invite new user"/>
      <div className={css.profileCards}>
        <div>
          <Card title={t.BASIC_INFO}>
            <Formik
              initialValues={inviteForm.INITIAL_VALUES}
              validationSchema={inviteForm.VALIDATION}
              onSubmit={handleSubmit}
            >
              {({ submitForm }: FormikProps<FormikValues>) => (
                <Form onSubmit={preventDefault}>
                  <div className={css.fields}>
                    <Input.Field
                      name="name"
                      className={css.password}
                      type="text"
                      label={t.NAME}
                    />
                    <Input.Field
                      name="email"
                      className={css.password}
                      type="email"
                      label={t.EMAIL}
                    />
                    <Input.Field
                      name="client_portal_role_id"
                      className={css.password}
                      type="select"
                      label={t.SELECT_ROLE}
                      options={accessRoles.map(r => ({ id: String(r.id), name: r.name }))}
                    />
                    <Button
                      type="submit"
                      label={t.SEND_INVITATION}
                      onClick={submitForm}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      </div>
    </div>
  );

};

export default Invite;
