// core
import React, { useState } from 'react';

// components
import { Card, AvatarWithTitle, Button, Dialog, Input, Loader } from 'components';

// styles
import css from '../Dashboard.module.scss';

import LogoAvatar from 'assets/images/webswing avatar default.svg';
import { IUser } from '../../../../api/interfaces/IUser';
import { Formik, FormikProps, FormikValues } from 'formik';
import { IRequestVerificationForm, RequestVerificationForm } from '../../../../utils/forms/DashboardForms';
import APIClass from '../../../../api';
import { IDedicatedContact } from '../../../../api/interfaces/IDedicatedContact';
import { ButtonDefault } from '../../../../components/basic/Button/variants/ButtonDefault/ButtonDefault';
import classnames from 'classnames';

export interface IMyContactWidget {
  /**
   *
   */
  user?: IUser

  support?: boolean
}

export const MyContactWidget = ({ user, support = false }: IMyContactWidget) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const verificationForm = new RequestVerificationForm();

  const verified = null !== user?.company_id && null !== user?.company?.support_ticket_level_id;

  const toggle = () => {
    setOpen(open => !open);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const requestVerification = (values: IRequestVerificationForm) => {
    setIsLoading(true);
    APIClass.DashboardEndpoint.RequestVerification(values).then(res => {
      if (res?.success) {
        // showToastOk(res.message)
        toggle();
      }
      setIsLoading(false);
    });
    //, { setErrors }: FormikHelpers<IRegistrationForm>
  };

  let dedicatedUser: IDedicatedContact | null = null;

  const mail = support ? 'support@webswing.org' : 'sales@webswing.org';

  if (!support && user?.company?.dedicated_user) {
    dedicatedUser = user.company.dedicated_user;
  } else if (support && user?.company?.dedicated_support_user) {
    dedicatedUser = user.company.dedicated_support_user;
  }

  return (
    <Card title={`My ${support ? 'support' : ''} contact${!verified ? ' - not verified' : ''}`}>
      <AvatarWithTitle
        size="huge"
        src={dedicatedUser?.asset_photo || LogoAvatar}
        title={
          dedicatedUser ? dedicatedUser.username : <a href={`mailto:${mail}`} className={css.email}>{mail}</a>
        }
        subtitle={
          verified ? (
            dedicatedUser ? (
              <div className={css.detail}>
                {dedicatedUser.role_title || 'Business Development Associate'}
                <div className={classnames(css.email, css.detail)}>
                  <a href={`mailto:${mail}`} className={css.email}>{mail}</a>
                </div>
                {dedicatedUser.data.calendly_link ? (
                  <div className={css.scheduleCall}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={dedicatedUser.data.calendly_link}
                      className={css.email}
                    >
                      <ButtonDefault label="Schedule call" size="medium" />
                    </a>
                  </div>
                ) : null}
              </div>
            ) : null
          ) : (
            <div>
              Already a client?
              <div>
                <Button
                  className={css.button}
                  size="small"
                  label="Request verification"
                  onClick={openDialog}
                />
              </div>
            </div>
          )
        }
        classes={{ avatar: css.avatar, title: css.detail }}
      />
      <Dialog
        bOpen={open}
        title="Request verification"
        footer={false}
        onToggle={toggle}
      >
        <Formik
          initialValues={verificationForm.INITIAL_VALUES}
          validationSchema={verificationForm.VALIDATION}
          onSubmit={requestVerification}
        >
          {(form: FormikProps<FormikValues>) => (
            <div className={css.inputs}>
              <Input.Field
                className={css.input}
                label="Company"
                name="company"
                type="text"
                placeholder="Enter your company name"
              />
              <Input.Field
                className={css.input}
                label="Software"
                name="software"
                type="text"
                placeholder="Enter the name of software for which you use webswing"
              />
              <div className={css.wFooter}>
                {isLoading && <Loader className={css.spinner} size={15}/>}
                <Button type="submit" size="small" color="neutral" label="Cancel" onClick={toggle}/>
                <Button type="button" size="small" label="Verify me" onClick={form.submitForm}/>
              </div>
            </div>
          )}
        </Formik>
      </Dialog>
    </Card>
  );
};
