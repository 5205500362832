// .core
import React from 'react'
// components
import { DialogDefault, IDialogDefaultProps } from '../DialogDefault/DialogDefault'
// libraries
import cx from 'classnames'
// utils
import {} from 'utils'
// declarations

interface IRenderProps extends IDialogRPState {
  onToggle: (overrideVal?: boolean) => () => void
}
interface IDialogRPProps extends Omit<IDialogDefaultProps, 'onToggle'> {
  children(renderProps: IRenderProps): React.ReactNode
  classNameTrigger?: string
  /**
   * Trigger element that shows up the `<Dialog />` when clicked (mostly a `<Button /> || <Icon />`)
   */
  trigger: React.ReactNode
}
interface IDialogRPState {
  bOpen: boolean
}

/**
 * Dialog using React's Render Props feature
 */
export class DialogRP extends React.Component<IDialogRPProps, IDialogRPState> {
  state: IDialogRPState = {
    bOpen: !!this.props.bOpen,
  }

  // =============== I N I T ===============
  //   componentDidMount() {}
  //   componentWillUnmount() {}

  // =============== E V E N T S ===============
  onToggle = (val?: boolean) => () => {
    this.setState(prevState => ({
      bOpen: val ?? !prevState.bOpen,
    }))
  }

  render() {
    const { children, classNameTrigger, trigger, ...modalProps } = this.props

    return (
      <>
        {/* TRIGGER */}
        <div className={cx(classNameTrigger)} onClick={this.onToggle(true)}>
          {trigger}
        </div>

        {/* DIALOG */}
        <DialogDefault {...modalProps} bOpen={this.state.bOpen} onToggle={this.onToggle}>
          {children({
            ...this.state,
            onToggle: val => this.onToggle(val),
          })}
        </DialogDefault>
      </>
    )
  }
}
