// core
import React, { useState, useRef } from 'react'

// libraries
import cx from 'classnames'

// components
import { Dialog } from 'components'

// translation
// import { t } from 'i18n'

// partials
import css from '../Chatbot.module.scss'
import stunt from 'assets/animations/stunt_animation_final/owen_salto.mp4'
import showChat from 'assets/animations/stunt_animation_final/owen_nod.mp4'
import jump from 'assets/animations/stunt_animation_final/owen_skok.mp4'
import thumbsup from 'assets/animations/stunt_animation_final/owen_tup.mp4'
import poster from 'assets/animations/poster_chat.jpg'
import fall from 'assets/animations/stunt_animation_final/owen_pad.mp4'
import { Player, ControlBar, Shortcut } from 'video-react'
import { ButtonDefault } from 'components/basic/Button/variants/ButtonDefault/ButtonDefault'
import { Chat } from './Chat'

export interface IOwenProps {
  /**
   * whether dialog is shown
   */
  showDialog: boolean
  /**
   * function to close dialog
   */
  onClose: (stop?: boolean) => void
}

export const OwenDialog = ({ showDialog, onClose }: IOwenProps) => {
  const ref = useRef()
  const [src, setSrc] = useState<string>(fall)
  const [first, setFirst] = useState<boolean>(true)

  const stuntMap = [jump, stunt, thumbsup, showChat]

  const handleSetSource = (src: string) => {
    setSrc(src)
    // @ts-ignore
    ref?.current?.load()
  }

  const handleStunt = () => {
    handleSetSource(
      stuntMap[Math.floor(Math.random() * (first ? stuntMap.length - 2 : stuntMap.length))]
    )
    if (first) setFirst(false)
  }

  const handleClose = () => {
    onClose()
    setFirst(true)
    handleSetSource(fall)
  }

  return (
    <Dialog
      footer={false}
      header={false}
      classNameWrap={css.dialogWrap}
      bOpen={showDialog}
      onToggle={handleClose}>
      <div className={css.dialog}>
        <div className={css.stunt}>
          <Player
            ref={ref}
            muted
            autoPlay
            playsInline
            poster={poster}
            width={200}
            height={325}
            fluid={false}
            className={css.player}>
            <source src={src} />
            <ControlBar disableCompletely className="my-class" />
            <Shortcut dblclickable={false} clickable={false} />
          </Player>

          <p
            className={cx(css.stuntText, {
              [css.shownText]: src === showChat || src === thumbsup,
            })}>
            Oh common, lets chat now
          </p>

          <ButtonDefault color="neutral" size="small" label="Make stunt" onClick={handleStunt} />

          <p
            className={cx(css.stuntText, {
              [css.shownText]: src !== showChat && src !== thumbsup,
            })}>
            Let me make a stunt to make some fun
          </p>
        </div>

        <Chat handleClose={onClose} />
      </div>
      <button className={css.closeButton} onClick={handleClose}>
        Close
      </button>
    </Dialog>
  )
}
