// core
import React, { useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom'

// components
import { PageHeader, Tabs, Tab, Button } from 'components'

// translation
import { t } from 'i18n'

// partials
import Password from './Partials/Password'
import Profile from './Partials/Profile'
import AccessRights from './Partials/AccessRights'
import UserManagement from './Partials/UserManagement'

// routes
import { changeTitle } from '../../routes';

// services
import { AuthService } from '../../../services/AuthService';

// styles
import css from './Settings.module.scss'
import { IUser } from '../../../api/interfaces/IUser';
import APIClass from '../../../api/APIClass';

export const Settings = () => {
  const tabMap = ['profile', 'password', 'management' , 'access'];
  const { tab } = useParams();
  const index = tabMap.findIndex(i => i === tab)
  const [activeTab, setActiveTab] = useState<number>(index >= 0 && index < tabMap.length ? index : 0)
  const history = useHistory();

  changeTitle(t.SETTINGS);

  const handleTabChange = useCallback((tabIndex: number) => {
    if (activeTab !== tabIndex) {
      history.push(`/settings/${tabMap[tabIndex]}`);
      setActiveTab(tabIndex)
    }
  }, [history, activeTab, tabMap])

  const updateUser = (user: IUser, errorCallback: (errors: any) => {}) => {
    return APIClass.SettingsEndpoint.UpdateProfile(user).then(res => {
      if (res?.success) {
        AuthService.currentUser = (res.content as IUser)
      } else {
        // @ts-ignore
        errorCallback(res.content.errors)
      }

      return res;
    })
  }

  const goToNewUser = useCallback(() => {
    history.push('/settings/management/create')
  }, [history]);

  return (
    <div>
      <PageHeader title={t.SETTINGS} />
      <Tabs
        button={activeTab === 2 ? (<Button size="small" hover="shadow" label={t.USERS.ADD_NEW} onClick={goToNewUser} />) : undefined}
        defaultTab={activeTab}
        onChangeTab={handleTabChange}>
        <Tab label="Profile" className={css.tabNoOverflow}>
          <Profile user={AuthService.currentUser!} updateUser={updateUser} />
        </Tab>
        <Tab label="Password">
          <Password />
        </Tab>
        {AuthService.hasPermission('settings', 'manage') ? (<Tab label="User management">
          <UserManagement />
        </Tab>) : <span />}
        {AuthService.hasPermission('settings', 'manage') ? (<Tab label="Access rights">
          <AccessRights />
        </Tab>) : <span />}
      </Tabs>
    </div>
  )
}
