// API
import { ICompany } from 'api/interfaces/ICompany'
import { CallAPI } from '../APIUtils'

export class DownloadsEndpoint {
  async List() {
    return CallAPI({ method: 'POST', URL: `/downloads`, bAuthentificate: true })
  }

  async repoList() {
    return CallAPI({ method: 'POST', URL: `/downloads/repositories`, bAuthentificate: true })
  }

  async UpdateCompanyTokenLock(companyId: number | null, body: ICompany | undefined) {
    return CallAPI({
      method: 'PATCH',
      URL: `/downloads/repositories/${companyId}`,
      body,
      bAuthentificate: true,
    })
  }

  async downloadFile(file: string) {
    return CallAPI({
      method: 'GET',
      URL: `/downloads/${file}`,
      bAuthentificate: true,
    })
  }

  async getStrings() {
    return CallAPI({ method: 'GET', URL: `/downloads/strings`, bAuthentificate: true })
  }
}
