// .core
import React from 'react'
// i18n
import { t } from 'i18n'
// utils
import { IPageProps, TIcon } from 'utils'
import { LoginPage } from './auth/Login/LoginPage'
import { RegisterPage } from './auth/Register/RegisterPage'
import { ForgottenPasswordPage } from './auth/ForgottenPassword/ForgottenPasswordPage'
import { Dashboard } from './core/Dashboard/Dashboard'
import { Settings } from './core/Settings/Settings'
import { Downloads } from './core/Downloads/Downloads'
import { Issues } from './core/Issues/Issues'
import { IssueDetail } from './core/Issues/IssueDetail'
import User from './core/Settings/Partials/Management/User'
import Invite from './core/Settings/Partials/Management/Invite'
import { DownloadsOld } from './core/DownloadsOld/DownloadsOld'

/**
 * Config for UI general elements of a page
 */
export interface IUIConfig {
  bShowBreadcrumbs?: boolean
  bShowFooter?: boolean
  bShowHeader?: boolean
  bShowHeaderSearchbar?: boolean
  /**
   * Icon to use in sidebar - if the app has one
   */
  icon?: TIcon
}

/**
 * Config for navigation route (a page)
 */
export interface IRoute<RouteStack> {
  component:
    | React.LazyExoticComponent<any>
    | React.FunctionComponent<IPageProps>
    | React.ComponentClass<IPageProps>
  name: string
  path: RouteStack
  exact?: boolean
  /**
   *
   * ! WORK IN PROGRESS ! - NOT YET IMPLEMENTED !
   *
   * Collection of subroutes for this page - Home -> Table View -> Entry Detail -> Entry Detail Tab etc.
   * Also used for generatinng breadcrumbs
   */
  subroutes?: IRoute<RouteStack>[]
  UIConfig?: IUIConfig
}

/**
 * List of routes for non-authentificated users
 */
export type TRoutesAuth = '' | 'forgotten-password' | 'login/accept/:driver' | 'register'

/**
 * List of routes for authentificated users
 */
export type TRoutesCore =
  | ''
  | 'dashboard'
  | 'settings/management/create'
  | 'settings/management/:id'
  | 'settings/:tab?'
  | 'downloads-old/:folder*'
  | 'support'
  | 'support/:id'
  | 'downloads/:tab?'
/**
 * List of all available routes
 */
export type TRoutes = TRoutesAuth | TRoutesCore

/**
 * Routing config for authentification pages - when user is not logged in yet
 */
export const ROUTES_AUTH: IRoute<TRoutesAuth>[] = [
  {
    component: LoginPage,
    name: t.LOGIN,
    path: 'login/accept/:driver',
    exact: true,
  },
  {
    component: RegisterPage,
    name: t.REGISTER,
    path: 'register',
    exact: true,
  },
  {
    component: ForgottenPasswordPage,
    name: t.FORGOTTEN_PASSWORD,
    path: 'forgotten-password',
    exact: true,
  },
  {
    component: LoginPage,
    name: t.LOGIN,
    path: '',
    exact: true,
  },
]

/**
 * Routing config for authentificated pages - core of the app - when user is already logged in yet
 */
export const ROUTES_CORE: IRoute<TRoutesCore>[] = [
  {
    component: Dashboard,
    name: t.DASHBOARD,
    path: 'dashboard',
  },
  {
    component: Invite,
    name: t.INVITE,
    path: 'settings/management/create',
    exact: true,
  },
  {
    component: User,
    name: t.PROFILE,
    path: 'settings/management/:id',
    exact: true,
  },
  {
    component: Settings,
    name: t.SETTINGS,
    path: 'settings/:tab?',
    exact: true,
  },
  {
    component: Downloads,
    name: t.DOWNLOADS,
    path: 'downloads/:tab?',
    exact: true
  },
  {
    component: DownloadsOld,
    name: t.DOWNLOADS,
    path: 'downloads-old/:folder*',
    exact: false,
  },
  {
    component: Issues,
    name: t.ISSUES,
    path: 'support',
    exact: true,
  },
  {
    component: IssueDetail,
    name: t.ISSUES_SUPPORT,
    path: 'support/:id',
    exact: true,
  },
  {
    component: Dashboard,
    name: t.DASHBOARD,
    path: '',
    exact: true,
  },
]

export const changeTitle = (title: string) => {
  document.title = `${title} | Client portal | Webswing`
}
