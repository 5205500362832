// .core
import React from 'react'
// libraries
import cx from 'classnames'
import RCTooltip from 'rc-tooltip'
// styles
// import 'rc-tooltip/assets/bootstrap.css'
import 'rc-tooltip/assets/bootstrap_white.css'
// import css from './Tooltip.module.scss'
// utils
import { TPos } from 'utils'

interface ITooltipProps {
  /**
   * A custom component to draw instead of the defaul arrow
   *
   * #NOTE: If not specified a global class will be applied which hides the default arrow (more in styles/index.scss at rc-no-arrow class)
   */
  arrow?: React.ReactNode
  /**
   * Type of animation to play for the tooltip
   *
   * MORE INFO AT: https://github.com/react-component/animate
   */
  animation?: string
  /**
   * Whether the Tooltip should get destroyed and unmounted once it closes
   */
  bUnmountOnClose?: boolean
  /**
   * Elements to display the tooltip around when hovering over them
   */
  children: React.ReactElement<React.ReactNode>
  /**
   * Classname applied to the entire tooltip overlay
   */
  className?: string
  /**
   * Content of the tooltip to display
   */
  content: React.ReactNode
  /**
   * Delay of showing the tooltip in miliseconds
   *
   * @default 250
   */
  delay?: number
  /**
   * Margin offset of the tooltip on the X axis, value is linked to the `position` prop
   *
   * @default 0
   */
  offsetX?: number
  /**
   * Margin offsets of the tooltip on the Y axis, value is linked to the `position` prop
   *
   * @default 0
   */
  offsetY?: number
  /**
   * Position of the tooltip relevant to the element it's wrapping
   *
   * @default 'bottom'
   */
  position?: TPos
  /**
   * Styling of the tooltip
   */
  styleOverlay?: React.CSSProperties
  /**
   * Styling of the tooltip's content
   */
  styleOverlayContent?: React.CSSProperties
  /**
   * How the Tooltip should be triggered, either by clicking its childrend or hovering over them
   */
  trigger?: 'click' | 'hover'
}

export const Tooltip = ({
  animation,
  arrow,
  bUnmountOnClose,
  children,
  className,
  content,
  delay,
  offsetX = 0,
  offsetY = 0,
  position = 'bottom',
  styleOverlay,
  styleOverlayContent,
  trigger = 'hover',
}: ITooltipProps) => (
  <RCTooltip
    align={{ offset: [offsetX, offsetY] }}
    arrowContent={arrow}
    destroyTooltipOnHide={bUnmountOnClose}
    mouseEnterDelay={delay}
    mouseLeaveDelay={delay}
    overlay={content}
    overlayClassName={cx(!arrow && 'rc-no-arrow', className)}
    overlayInnerStyle={styleOverlayContent}
    overlayStyle={styleOverlay}
    placement={position}
    transitionName={animation}
    trigger={trigger}>
    {children}
  </RCTooltip>
)
