/* eslint-disable react/jsx-no-useless-fragment */
// core
import React, { useState } from 'react'

// styles
import css from '../Downloads.module.scss'

// components
import { Button, Card, CardContent, Heading, NoData } from 'components'

// translation
import { IFilesTableEntry } from './FilesTable'
import { FilesTabs } from './FilesTabs'
import { IDist } from '../Dist'

interface IVersionCardProps {
  item: IDist
  loading: boolean
  index: number
}

export const VersionCard = ({ item, loading }: IVersionCardProps) => {
  const [expanded, setExpanded] = useState<boolean>(typeof item.card_open === 'boolean'? item.card_open:false)

  const getFilesTabs = (files: IFilesTableEntry[]) => {
    const hasAnyStableVersionType = files.findIndex(f => (f.versionType === 'stable' || f.versionType === 'lts') && f.isDownloadable) !== -1
    const sortingArray = ['standard', 'cluster', 'admin-console', 'security-modules', 'test-tool', 'sources', 'migration-toolkit', 'java8-support', 'customization', 'report'];
    const result = files
      .filter((file, index, self) => {
        return self.findIndex(v => v.fileType === file.fileType) === index
      })
      .map(v => v.fileType)

    const sortedTabs = sortingArray.filter((item) => result.includes(item))

    return hasAnyStableVersionType ? sortedTabs : ['eval',...sortedTabs]
  }

  const toggleContent = () => {
    setExpanded(s=>!s);
  }

  return (
    <Card className={css.marginBottom}>
      <CardContent>
        {item.version && item.phase && (
          <Heading
            variant="h3"
            title={item.title || item.version}
            rightTitle={item.phase}
            buttons={[
              <Button
                key="moreButton"
                color="neutral"
                icon={expanded ? 'expand_less' : 'expand_more'}
              />,
            ]}
            onClick={toggleContent}
          />
        )}
        {expanded ? (
          <>
            {item.files?.length ? (
              <FilesTabs files={item.files} tabs={getFilesTabs(item.files)} loading={loading} />
            ) : (
              <NoData message="Comming soon" />
            )}
          </>
        ) : null}
      </CardContent>
    </Card>
  )
}
