import React from 'react'

export const GeneralCell = (cellData: string) => (
  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>{cellData}</div>
)

export const listRules = [
  {
    rule: 'THE WEBSWING RELEASE CYCLE FOLLOWS ORACLE’S DEVELOPMENT CYCLE',
    desc: 'There will be a major release every 6 months (scheduled for April and October)',
  },
  {
    rule: 'WEBSWING WILL SUPPORT THE TWO LATEST LTS (long term support) VERSIONS OF JAVA (depends on the Oracle’s development cycle and\n' +
        'support)',
    desc: '',
  },
  {
    rule: 'JAVA LTS SUPPORT WILL BE INTRODUCED IN WEBSWING WITH ONE CYCLE DELAY',
    desc:
      'E.g., Java 11 support released in September 2018 will be available in Webswing on March 2019',
  },
  {
    rule:
      'BUG FIXES AND SECURITY PATCHES ARE RELEASED ONCE A MONTH FOR THE MOST RECENT MAJOR VERSION',
    desc: '',
  },
  {
    rule:
      'EACH WEBSWING VERSION INTRODUCING A NEW LTS JAVA SUPPORT, WILL BE SUPPORTED FOR PAYING CUSTOMERS FOR 3 YEARS, OR INDEFINITELY FOR CUSTOMERS WITH EXTENDED SUPPORT PLANS',
    desc: '',
  },
  {
    rule: 'AFTER VERSION 2.7, VERSIONS WILL BE NUMBERED WITH FOLLOWING PATTERN: YY.n.m',
    desc:
      'Where YY is the 2-digit form of year, n is 1(April) or 2(October), m is 1-5 minor patch release number',
  },
]

export const listJava = [
  {
    current: 'Java 11 (LTS)',
    sept21: 'Java 17 (LTS)',
    mar22: 'Java 18',
    sept22: 'Java 19',
    mar23: 'Java 20',
    sept23: 'Java 21 (LTS)',
    mar24: 'Java 22',
    sept24: 'Java 23',
    mar25: 'Java 24',
    sept25: 'Java 25 (LTS)',
    mar26: 'Java 26',
  },
]

export const listWsVersion = [
  {
    version: 'Release date',
    '212': 'Oct-2021',
    '221lts': 'Apr-2022',
    '222': 'Oct-2022',
    '231': 'Apr-2023',
    '232lts': 'Oct-2023',
    '241': 'Apr-2024',
    '242': 'Oct-2024',
    '251lts': 'Apr-2025',
    '252': 'Oct-2025',
    '261': 'Apr-2026',
  },
  {
    version: 'Supported Java version',
    '212': 'Java 8, 11',
    '221lts': 'Java 8, 11, 17',
    '222': 'Java 8, 11, 17',
    '231': 'Java 8, 11, 17',
    '232lts': 'Java 8, 11, 17',
    '241': 'Java 8, 11, 17, 21',
    '242': 'Java 8, 11, 17, 21',
    '251lts': 'Java 8, 11, 17, 21',
    '252': 'Java 8, 11, 17, 21',
    '261': 'Java 8, 11, 17, 21, 25',
  },
  {
    version: 'Monthly releases',
    '212': '6 months',
    '221lts': '6 months',
    '222': '6 months',
    '231': '6 months',
    '232lts': '6 months',
    '241': '6 months',
    '242': '6 months',
    '251lts': '6 months',
    '252': '6 months',
    '261': '6 months',
  },
  {
    version: 'LTS releases',
    '212': 'N/A',
    '221lts': '3 years',
    '222': 'N/A',
    '231': 'N/A',
    '232lts': '3 years',
    '241': 'N/A',
    '242': 'N/A',
    '251lts': '3 years',
    '252': 'N/A',
    '261': 'N/A',
  },
  {
    version: 'Extended support releases',
    '212': 'indefinite',
    '221lts': 'indefinite',
    '222': 'indefinite',
    '231': 'indefinite',
    '232lts': 'indefinite',
    '241': 'indefinite',
    '242': 'indefinite',
    '251lts': 'indefinite',
    '252': 'indefinite',
    '261': 'indefinite',
  },
]

export const listWsABVersion = [
  {
    version: 'Minor patch release A.B.1',
    '212': 'Nov-21',
    '221lts': 'May-22',
    '222': 'Nov-22',
    '231': 'May-23',
    '232lts': 'Nov-23',
    '241': 'May-24',
    '242': 'Nov-24',
    '251lts': 'May-25',
    '252': 'Nov-25',
    '261': 'May-26',
  },
  {
    version: 'Minor patch release A.B.2',
    '212': 'Dec-21',
    '221lts': 'Jun-22',
    '222': 'Dec-22',
    '231': 'Jun-23',
    '232lts': 'Dec-23',
    '241': 'Jun-24',
    '242': 'Dec-24',
    '251lts': 'Jun-25',
    '252': 'Dec-25',
    '261': 'Jun-26',
  },
  {
    version: 'Minor patch release A.B.3',
    '212': 'Jan-22',
    '221lts': 'Jul-22',
    '222': 'Jan-23',
    '231': 'Jul-23',
    '232lts': 'Jan-24',
    '241': 'Jul-24',
    '242': 'Jan-25',
    '251lts': 'Jul-25',
    '252': 'Jan-25',
    '261': 'Jul-26',
  },
  {
    version: 'Minor patch release A.B.4',
    '212': 'Feb-22',
    '221lts': 'Aug-22',
    '222': 'Feb-23',
    '231': 'Aug-23',
    '232lts': 'Feb-24',
    '241': 'Aug-24',
    '242': 'Feb-25',
    '251lts': 'Aug-25',
    '252': 'Feb-25',
    '261': 'Aug-26',
  },
  {
    version: 'Minor patch release A.B.5',
    '212': 'Mar-22',
    '221lts': 'Sept-22',
    '222': 'Mar-23',
    '231': 'Sept-23',
    '232lts': 'Mar-24',
    '241': 'Sept-24',
    '242': 'Mar-25',
    '251lts': 'Sept-25',
    '252': 'Mar-25',
    '261': 'Sept-26',
  },
  {
    version: 'Minor patch release A.B.XX',
    '212': 'N/A',
    '221lts': 'until MAR2025',
    '222': 'N/A',
    '231': 'N/A',
    '232lts': 'until SEPT2026',
    '241': 'N/A',
    '242': 'N/A',
    '251lts': 'until MAR2028',
    '252': 'N/A',
    '261': 'N/A',
  },
  {
    version: 'Minor patch release A.B.YY',
    '212': 'indefinite',
    '221lts': 'indefinite',
    '222': 'indefinite',
    '231': 'indefinite',
    '232lts': 'indefinite',
    '241': 'indefinite',
    '242': 'indefinite',
    '251lts': 'indefinite',
    '252': 'indefinite',
    '261': 'indefinite',
  },
]
