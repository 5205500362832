// core
import React, { useEffect, useState } from 'react';

// components
import { Card, AvatarWithTitle, Loader } from 'components';

// styles
import css from '../Dashboard.module.scss';

import API from 'api';
import APIClass from 'api';

export interface IArticle {
  id: number
  name: string
  url: string
  asset_sales_email_image: string
}

export const LatestNewsWidget = () => {
  const [article, setArticle] = useState<IArticle>();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      API.DashboardEndpoint.LatestNews()
        .then(res => {
          if (res?.success) {
            setArticle(res.content as IArticle);
            setLoaded(true);
          }
        });
    }
  }, [loaded]);

  const openUrl = () => {
    APIClass.DashboardEndpoint.LatestNewsTrack({ b: article!.id });
    window.open(article?.url, '_blank');
  };

  return (
    <Card title="Latest news">
      {loaded ? (
        <AvatarWithTitle
          size="huge"
          src={article?.asset_sales_email_image}
          title={article?.name}
          subtitle={article?.url}
          className={css.latestNews}
          classes={{ subtitle: css.subtitle, title: css.title, avatar: css.avatarFull }}
          onCLick={openUrl}
        />
      ) : (<Loader.Line/>)}
    </Card>
  );
};
