// .core
import React, { ReactNode } from 'react'
// libs
import classnames from 'classnames'
// navigation
import { IUIConfig } from 'pages/routes'
// styles
import css from './Content.module.scss'

interface IContentProps extends Pick<IUIConfig, 'bShowHeader'> {
  children: ReactNode
  className?: string
}

export const Content: React.FC<IContentProps> = ({ className, children }: IContentProps) => (
  <div className={classnames(css.content, className)}>{children}</div>
)
