// core
import React from 'react'

// libraries
import classnames from 'classnames'

// components
import { IComponentProps } from 'components'

// styles
import css from './IssueStatus.module.scss'

export interface IPointProps extends IComponentProps {
  /**
   * Styling of the point, default theme color will be used if none specified.
   */
  color: 'new' | 'opened' | 'processing' | 'waiting' | 'resolved' | 'closed' | 'reopened' | 'backlog' | 'expired'
}

export const IssueStatus = ({ color }: IPointProps) => {
  return <div className={classnames(css.root, css[color])} />
}
