export const en = {
  // A
  ADD_NEW_ROLE: 'Add new role',
  // B
  BACK: 'Back',
  BASIC_INFO: 'Basic info',
  // C
  CANCEL: 'Cancel',
  CLOSE: 'Close',
  COMPANY: 'Company',
  CONFIRM: 'Confirm',
  CONTINUE: 'Continue',
  CHANGE_PASSWORD: 'Change password',
  CONFIRM_PASSWORD: 'Confirm password',

  // D
  DASHBOARD: 'Dashboard',
  DELETE: 'Delete',
  DIALOG: 'Dialog',
  DISTROS: 'Distributions',
  DOCUMENTATION: 'Documentation',
  DOWNLOADS: 'Downloads',

  // E
  EMAIL: 'E-mail',
  ENTER_VALUE: 'Enter a value',
  ERROR_BOUNDRY: 'Whops! Something went wrong.',

  // F
  FORGOTTEN_PASSWORD: 'Forgotten Password',

  // G
  // H
  HOME: 'Home',

  // I
  INVITE: 'Invite new user',
  ISSUES: 'Support',
  ISSUES_SUPPORT: 'Support',
  // J
  // K
  // L
  LINKEDIN: 'LinkedIn',
  LOGIN: 'Login',
  LOGOUT: 'Logout',

  // M
  MIGRATE: 'Migration Demo',
  // N
  NAME: 'Name',
  NEW_PASSWORD: 'New password',
  NEXT: 'Next',
  NO_DATA: 'There are no records for this section',

  // O
  OLD_PASSWORD: 'Old password',
  // P
  PERMISSIONS: 'Permissions',
  PASSWORD: 'Password',
  PROFILE: 'Profile',
  PHONE: 'Phone',
  PLAN: 'Plan',

  // Q
  // R
  REGISTER: 'Register',
  RESET_PASSWORD: 'Reset password',
  REPOS: 'Repositories',

  // S
  SAVE: 'Save',
  SELECT_OR_CREATE_NEW: 'Select or create new ...',
  SETTINGS: 'Settings',
  SELECT_ROLE: 'Select Access Group',
  SKIP: 'Skip',
  SUBMIT: 'Submit',
  STATUS: 'Status',
  STATISTICS: 'Statistics',
  SEND_INVITATION: 'Send invitation',
  SEND_RESET_EMAIL: 'Request reset instructions',

  // T
  TICKET: 'Ticket',
  TOAST_201: 'Entry was successfully created',
  TOAST_403: 'Unauthorized access! Logging you out...',
  TOAST_404: '404 Not Found',
  TOAST_408: 'Request timed out! Please try again later.',
  // U
  URL: 'URL',
  USERS: {
    ADD_NEW: 'Add new user',
  },
  // V
  // W
  WIDGETS: 'Widgets',
  // X
  // Y
  // Z

  /**
   * 1st of the only 2 grouped translations
   *
   * why ?
   * - to have translation for enums received from the BE - direct access to key-value via index
   *
   * @example
   * <Select
   *    value={t.ENUMS[form.values.type]}
   *    onChange={enumKey => bind(form, 'type', enumKey)}
   * />
   */
  ENUMS: {} as { [key: string]: string },

  /**
   * 2nd of the only 2 grouped translations
   *
   * why ?
   * - hard to come up w/ orignal naming
   * - would mix in w/ the rest
   * - is only used in form files for Yup validation
   * - in general, has a different wording than the rest
   */
  VALIDATION: {
    EMAIL: 'Please enter a correct e-mail',
    PASSWORD_LENGTH: (num: number) => `Password has to have at least ${num} characters`,
    PASSWORD_MATCH: 'Passwords need to match!',
    REQUIRED: 'Required',
  },
}
