// core
import React from 'react'
// import cx from 'classnames';

// styles
import css from '../Downloads.module.scss'

import { Button, Icon } from 'components'

interface IContentLockedProps {
  isKeyPerson?: boolean | undefined
  onClick?: () => void
  children?: React.ReactNode
}
export const ContentLocked = ({ isKeyPerson, onClick, children  }: IContentLockedProps) => {
  return (
    <div className={css.contentLocked}>
      {children?
        children:
        <>
        <Icon size="extraLarge" color="blue" name="info" />
        <h1>Your access to repositories has not been activated yet.</h1>
        {isKeyPerson ? (
          <Button label="Activate" color="blue" onClick={onClick} />
        ) : (
          <p>
            Please contact key person at your company to activate this section for you.
            <br /> or ask our <a href="sales@webswing.org">sales@webswing.org</a>
          </p>
        )}
        </>
      }
    </div>
  )
}
