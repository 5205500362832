// API
import { CallAPI } from '../APIUtils';
import { IChangePasswordForm } from '../../utils/forms/SettingsForms';
import { IUser } from '../interfaces/IUser';

export class SettingsEndpoint {
  async Footer() {
    return CallAPI({ method: 'GET', URL: `/settings/footer` });
  }

  async ChangePassword(body: IChangePasswordForm) {
    return CallAPI({ method: 'POST', URL: `/settings/change-password`, body, bAuthentificate: true });
  }

  async Roles() {
    return CallAPI({ method: 'GET', URL: `/settings/roles`, bAuthentificate: true });
  }

  async RolesPortal() {
    return CallAPI({ method: 'GET', URL: `/settings/roles/portal`, bAuthentificate: true });
  }

  async CreatePortalRole(body: { name: string }) {
    return CallAPI({ method: 'POST', URL: `/settings/roles/portal`, body, bAuthentificate: true });
  }

  async UpdatePortalRolePermissions(roleId: number, body: { menu: string, permission: string, enabled: boolean }) {
    return CallAPI({ method: 'PUT', URL: `/settings/roles/portal/${roleId}`, body, bAuthentificate: true });
  }

  async UpdatePortalRole(roleId: number, body: { is_admin: boolean }) {
    return CallAPI({ method: 'PATCH', URL: `/settings/roles/portal/${roleId}`, body, bAuthentificate: true });
  }

  async CreateRole(body: { name: string }) {
    return CallAPI({ method: 'PUT', URL: `/settings/roles`, body, bAuthentificate: true });
  }

  async UpdateProfile(body: IUser) {
    return CallAPI({ method: 'PATCH', URL: `/settings/profile`, body, bAuthentificate: true });
  }

  async Users() {
    return CallAPI({ method: 'GET', URL: `/settings/users`, bAuthentificate: true });
  }

  async User(id: number) {
    return CallAPI({ method: 'GET', URL: `/settings/users/${id}`, bAuthentificate: true });
  }

  async UpdateUser(id: number, body: IUser) {
    return CallAPI({ method: 'PATCH', URL: `/settings/users/${id}`, bAuthentificate: true, body });
  }

  async InviteUser(body: { name: string, email: string, client_portal_role_id: string | null }) {
    return CallAPI({ method: 'POST', URL: `/settings/users/invite`, bAuthentificate: true, body });
  }

  async RemoveUser(id: number) {
    return CallAPI({ method: 'DELETE', URL: `/settings/users/${id}`, bAuthentificate: true });
  }
}
