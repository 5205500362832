// .core
import React from 'react'
// libraries
import cx from 'classnames'
import RCDatePicker, { ReactDatePickerProps } from 'react-datepicker'
// styles
// import css from './DatePicker.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
// import 'react-datepicker/dist/react-datepicker-cssmodules.css'

//
// #NOTE:
// Before you start customizing this component to your project, head on to:
// https://reactdatepicker.com/#example-date-range-with-disabled-navigation-shown
// for an extensive collection of demos for pretty much every single one of the 134 props
//

interface IDatePickerProps extends Omit<ReactDatePickerProps, 'value' | 'onChange'> {
  bClearable?: boolean
  bDisabled?: boolean
  bMultiSelect?: boolean
  bReadOnly?: boolean
  bRequired?: boolean
  classNameCalendar?: string
  value?: Date | null | undefined
  onChange?(value: Date | [Date, Date] | null): void
}

export const DatePicker = ({
  bClearable,
  bDisabled,
  bMultiSelect,
  bReadOnly,
  bRequired,
  className,
  classNameCalendar,
  value,
  onChange,
  ...remaininProps
}: IDatePickerProps) => {
  const _onChange = (date: Date | [Date, Date], _event?: React.SyntheticEvent<any, Event>) => {
    onChange?.(date)
  }

  return (
    <RCDatePicker
      {...remaininProps}
      className={cx(/* css.wDatePicker, */ className)}
      calendarClassName={classNameCalendar}
      disabled={bDisabled}
      disabledKeyboardNavigation={bDisabled}
      isClearable={bClearable}
      readOnly={bReadOnly}
      required={bRequired}
      selected={value}
      selectsRange={bMultiSelect}
      onChange={_onChange}
    />
  )
}
