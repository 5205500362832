// .core
import React, { ReactNode } from 'react'
// components
import { Icon } from '../Icon/Icon'
// libraries
import cx from 'classnames'
// styles
import css from './Chip.module.scss'

interface IChipProps {
  bDisabled?: boolean
  className?: string
  label?: string | ReactNode
  color?: 'white' | 'neutral' | 'primary' | 'success' | 'blue'
  type?: 'fill' | 'outline'
  onClick?(): void
  onDelete?(): void
}

export const Chip = ({
  bDisabled,
  className,
  color = 'blue',
  label,
  type = 'fill',
  onClick,
  onDelete,
}: IChipProps) => {
  const _onClick = () => {
    !bDisabled && onClick && onClick()
  }

  const _onDelete = () => {
    !bDisabled && onDelete && onDelete()
  }

  return (
    <div
      className={cx(
        css.wChip,
        css[type],
        css[color],
        onClick && 'gClickable',
        bDisabled && css.bDisabled,
        className
      )}
      onClick={_onClick}>
      {/* LABEL */}
      {label && <span>{label}</span>}

      {/* DELETE */}
      {onDelete && <Icon name="x-circle" onClick={_onDelete} />}
    </div>
  )
}
