// .core
import React, { ReactNode } from 'react'

// components
import { ITabProps } from './Tab'
// libraries
import cx from 'classnames'
// styles
import css from './Tabs.module.scss'

interface ITabsProps {
  /**
   * Collection of HTML elements each representing a `Tab`
   */
  children: React.ReactElement<ITabProps>[]
  className?: string
  /**
   * Index of the selected `Tab` by default
   *
   * @default 0
   */
  defaultTab?: number
  onChangeTab?(tabIndex: number): void
  button?: ReactNode
}

export const Tabs: React.FC<ITabsProps> = ({
  children,
  className,
  button,
  defaultTab = 0,
  onChangeTab,
}: ITabsProps) => {
  const [currentTab, setCurrentTab] = React.useState<number>(defaultTab)

  const _onSelectTab = (tabIndex: number) => () => {
    setCurrentTab(tabIndex)
  }

  React.useEffect(() => {
    // tabIndex === currentTab &&
    onChangeTab?.(currentTab) // #CHECK: if the 1st condition is necessary, hooks are synchronous unlike setState()
  }, [currentTab, onChangeTab])

  return (
    <div className={cx(css.wTabs, className)}>
      {/* TABS - header */}
      <div className={cx(css.wTabsRow)}>
        <div>
          {children.map((tab, index) =>
            React.cloneElement(tab, {
              ...tab.props,
              key: 'tab_' + index,
              bActive: currentTab === index,
              onClick: _onSelectTab(index),
            })
          )}
        </div>
        {button ? <div>{button}</div> : null}
      </div>

      {/* TABS'S CONTENT */}
      <div className={cx(css.wTabContent)}>{children[currentTab].props.children}</div>
    </div>
  )
}
