// core
import React, { memo } from 'react'

import classnames from 'classnames'

// components
import { IComponentProps } from 'components'

// styles
import css from './Backdrop.module.scss'

export interface IBackdropProps extends IComponentProps {
  /**
   * Whether bacdrop should be visible
   */
  visible: boolean
  /**
   * Css z-index value
   */
  zIndex?: number
  /**
   * Callback to run on backdrop click
   */
  onClick?: (e: any) => void
}

export const Backdrop = memo(function Backdrop({
  visible,
  zIndex,
  onClick,
  className,
}: IBackdropProps) {
  return (
    <div
      className={classnames(css.root, visible ? css.visible : '', className)}
      style={{ zIndex }}
      onClick={onClick}
    />
  )
})
