// core
import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

// api
import { IClientPortalRole, IContactItem, IUser } from 'api/interfaces/IUser';

// components
import { Input, Card } from 'components';

// translation
import { t } from 'i18n';

// styles
import css from '../Settings.module.scss';
import { AuthService } from '../../../../services/AuthService';
import { ISelectItem } from '../../../../utils';
import APIClass from '../../../../api';
import { FieldArray, Formik, Form, FormikProps, FormikValues, FormikHelpers } from 'formik';
import { IProfileBasicForm, ProfileBasicForm } from '../../../../utils/forms/SettingsForms';

interface IProfileProps {
  user: IUser

  updateUser?(user: IUser, cb?: (errors: any) => void): Promise<any>
}

const profileBasicForm = new ProfileBasicForm();

const Profile = ({ user, updateUser }: IProfileProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [roles, setRoles] = useState<ISelectItem[]>([]);
  const [accessRoles, setAccessRoles] = useState<IClientPortalRole[]>([]);

  const loadAccessRoles = () => {
    APIClass.SettingsEndpoint.RolesPortal().then(res => {
      if (res?.success) {
        const roles = res.content as IClientPortalRole[];
        setAccessRoles(roles);
      }
    });
  };

  const handleRoleChange = (item: ISelectItem | null) => {
    if (null === item) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      updateUser?.({ ...user, contact_role_id: null });
      return;
    }
    if (item.hasOwnProperty('__isNew__') && item.__isNew__) {
      APIClass.SettingsEndpoint.CreateRole({ name: item.name.substr(0, 50) }).then(res => {
        if (res?.success) {
          const roles = res.content as ISelectItem[];
          setRoles(roles);
          const role = roles.find(role => role.name === item.name);
          // eslint-disable-next-line @typescript-eslint/camelcase
          updateUser?.({ ...user, contact_role_id: Number(role!.id) });
        }
      });
      return;
    }

    // eslint-disable-next-line @typescript-eslint/camelcase
    updateUser?.({ ...user, contact_role_id: Number(item.id) });
  };

  const handleAccessRoleChange = (item: ISelectItem | null) => {
    if (null === item) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      updateUser?.({ ...user, client_portal_role_id: null });
      return;
    }

    // eslint-disable-next-line @typescript-eslint/camelcase
    updateUser?.({ ...user, client_portal_role_id: Number(item.id) });
  };

  const loadRoles = useCallback(() => {
    if (loaded) {
      return;
    }
    APIClass.SettingsEndpoint.Roles().then(res => {
      if (res?.success) {
        setRoles(res.content as ISelectItem[]);
      }
      setLoaded(true);
    });
  }, [loaded]);

  useEffect(() => {
    if (!loaded) {
      loadRoles();
      loadAccessRoles();
    }
  }, [loaded, loadRoles]);

  const firstEmail = user.items.find(item => item.contact_item_type_id === 1)!.value;

  const handleItemsSubmit = debounce((values: FormikValues, { setErrors }: FormikHelpers<any>) => {
    if (values.items.filter((item: any) => item.__isNew__ && item.value === '').length === 0) {
      updateUser?.({ ...user, items: values.items }, errors => {
        setErrors(errors);
      });
    }
  }, 1500);

  const handleBasicSubmit = debounce((values: FormikValues, { setErrors }: FormikHelpers<IProfileBasicForm>) => {
    updateUser?.(({ ...user, ...values }), errors => {
      setErrors(errors);
    });
  }, 1500);

  function preventDefault(e: React.FormEvent) {
    e.preventDefault();
  }

  return (
    <div className={css.profileCards}>
      <div>
        <Card title="Basic info">
          {/*<Heading variant="h2" title="Basic info"/>*/}
          <div className={css.fields}>
            <Input
              bDisabled
              className={css.password}
              label={t.EMAIL}
              value={firstEmail}
              type="email"
            />
            <Input
              bDisabled
              className={css.password}
              label={t.COMPANY}
              value={user.company?.name || 'N/A'}
              type="text"
            />
            {user.company_id ? (
              <Input.Select
                label="Role in the company"
                name="contact_role_id"
                placeholder={t.SELECT_OR_CREATE_NEW}
                options={roles}
                bCreatable={AuthService.hasPermission('settings', 'manage')}
                bClearable={AuthService.hasPermission('settings', 'manage')}
                // bDisabled={!AuthService.hasPermission('settings', 'manage')}
                value={user.contact_role_id && user.contact_role ? ({ id: String(user.contact_role.id), name: user.contact_role.name }) : undefined}
                onChange={handleRoleChange}
              />
            ) : null}
          </div>
          <Formik
            initialValues={{ name: user.name, linked_in: user.linked_in }}
            validationSchema={profileBasicForm.VALIDATION}
            onSubmit={handleBasicSubmit}
          >
            <Form onSubmit={preventDefault}>
              <div className={css.fields}>
                <Input.Field
                  autoSubmit
                  label={t.NAME}
                  name="name"
                  className={css.password}
                  type="text"
                />
                <Input.Field
                  autoSubmit
                  label={t.LINKEDIN}
                  name="linked_in"
                  className={css.password}
                  type="text"
                />
              </div>
            </Form>
          </Formik>
        </Card>
      </div>
      <div>
        <Card title="Contact options">
          <Formik
            enableReinitialize
            initialValues={{ items: user.items.filter(item => item.value !== firstEmail) }}
            onSubmit={handleItemsSubmit}
          >
            {({ values: { items } }: FormikProps<FormikValues>) => (
              <Form onSubmit={preventDefault}>
                <FieldArray
                  validateOnChange
                  name="items"
                  render={arrayHelpers => (
                    <div>
                      <div className={css.fields}>
                        {items.map((item: IContactItem, index: number) => {
                          return <Input.Field
                            key={item.id}
                            autoSubmit
                            label={item.contact_item_type_name}
                            name={`items.${index}.value`}
                            className={css.password}
                            type="text"
                          />;
                        })}
                      </div>
                      <div data-a={1} className='select'>
                        <Input.Select
                          options={[{ id: '1', name: 'email' }, { id: '2', name: 'phone' }, { id: '3', name: 'link' }]}
                          placeholder="add new ..."
                          value={{ id: '0', name: '+ add new' }}
                          onChange={item => {
                            const newItem = item as ISelectItem;

                            arrayHelpers.push({
                              id: Date.now(),
                              value: '',
                              contact_item_type_name: newItem!.name,
                              contact_item_type_id: newItem!.id,
                              __isNew__: true,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                />
              </Form>
            )}

          </Formik>
        </Card>
      </div>
      <div>
        {user.id !== AuthService.currentUser!.id && AuthService.hasPermission('settings', 'manage') ? (
          <Card title="Permissions">
            <div className={css.fields}>
              <Input.Select
                bClearable
                label="Access group"
                options={accessRoles.map(role => ({ id: String(role.id), name: role.name }))}
                defaultValue={user.client_portal_role ? { id: String(user.client_portal_role.id), name: user.client_portal_role.name } : undefined}
                onChange={handleAccessRoleChange}
              />
            </div>
          </Card>
        ) : null}
      </div>
    </div>
  );
};

export default Profile;
