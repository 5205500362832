// .core
import React from 'react'
// components
import { ISkeletonProps } from './SkeletonUI'
// libraries
import cx from 'classnames'
// styles
import css from '../Skeleton.module.scss'

interface ISkeletonLineProps extends ISkeletonProps {}

// #TODO
export const SkeletonLine: React.FC<ISkeletonLineProps> = ({ className }: ISkeletonLineProps) => (
  <div className={cx(css.wSkeletonLine, className)} />
)
