// core
import React from 'react'
import cx from 'classnames'

// styles
import css from '../Downloads.module.scss'

// components
import { TableVirtualized } from 'components'
import { GeneralCell, listJava } from './data'

export const JavaTable = () => {
  return (
    <TableVirtualized
      className={css.javaTable}
      rowClassName={cx(css.borderless, css.fontSmall)}
      collection={listJava}
      rowHeight={45}
      headerHeight={45}
      headerClass={css.headerTable}
      minTableWidth={1400}
      columns={[
        {
          dataKey: 'current',
          label: 'Java',
          minWidth: 160,
          component: GeneralCell,
        },
        {
          dataKey: 'sept21',
          label: 'Sept-21',
          minWidth: 85,
          component: GeneralCell,
        },
        {
          dataKey: 'mar22',
          label: 'Mar-22',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: 'sept22',
          label: 'Sept-22',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: 'mar23',
          label: 'Mar-23',
          minWidth: 90,
          component: GeneralCell,
        },
        {
          dataKey: 'sept23',
          label: 'Sept-23',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: 'mar24',
          label: 'Mar-24',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: 'sept24',
          label: 'Sept-24',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: 'mar25',
          label: 'Mar-25',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: 'sept25',
          label: 'Sept-25',
          minWidth: 50,
          component: GeneralCell,
        },
        {
          dataKey: 'mar26',
          label: 'Mar-26',
          minWidth: 50,
          component: GeneralCell,
        },
      ]}
    />
  )
}
