// .core
import React from 'react'
// components
// import { Icon } from 'components'
// libraries
import { toast, ToastContainer, ToastOptions, ToastProps } from 'react-toastify'
// styles
import 'react-toastify/dist/ReactToastify.css'
import './Toast.module.scss'

export const Toast: React.FC<Partial<ToastProps>> = (props: Partial<ToastProps>) => (
  //
  // #NOTE: To enable testing, either uncomment the below div (don't worry, it won't affect the Toast's styling)
  //        or uncomment the `containerId` prop and in your test instead of `getByTestId` use `queryByAttribute('id', container, 'Toastify')`
  //        OR the best scenario would be add a `data-testid` directly to your custom Toast content...you gonna replace it anyway

  //   <div data-testid="Toastify">
  <ToastContainer
    hideProgressBar
    newestOnTop
    // containerId="Toastify"
    closeButton={false}
    {...props}
  />
  //   </div>
)

//
// #NOTE: To answer the question of "Why are toast toggling methods defined here", is because of the custom
//          content for the toast - the only way to enable it is to pass a component directly to `toast()` method
//          this means the file has to be .tsx, and this was the best place to put it in
//

/**
 * Unified method for displaying the global Toast, if not specified, defaults to `info` toast
 * @param message Text to display inside the Toast
 * @param toastConfig configuration for the Toast
 */
export const showToast = (message: string = '', toastConfig?: ToastOptions) =>
  message && toast(message, { autoClose: 2500, type: 'info', ...toastConfig })

/**
 * Specific method for displaying ERROR Toast only
 */
export const showToastErr = (message?: string, toastConfig?: ToastOptions) =>
  showToast(message, { ...toastConfig, autoClose: 5000, type: 'error' })
/**
 * Specific method for displaying SUCCESS Toast only
 */
export const showToastOk = (message?: string, toastConfig?: ToastOptions) =>
  showToast(message, { ...toastConfig, autoClose: 2500, type: 'success' })
/**
 * Specific method for displaying WARNING Toast only
 */
export const showToastWarn = (message?: string, toastConfig?: ToastOptions) =>
  showToast(message, { ...toastConfig, autoClose: 4000, type: 'warning' })
