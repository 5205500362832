// core
import React, { useCallback, useEffect, useState } from 'react';

// components
import { Chip, DateString, TableVirtualized, Button, Dialog } from 'components';

// styles
import css from '../Settings.module.scss';
import { IClientPortalRole, IUser } from '../../../../api/interfaces/IUser';
import APIClass from '../../../../api';
import { Link } from 'react-router-dom';

const UserManagement = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [deletedUser, setDeletedUser] = useState<IUser>();

  const loadUsers = () => {
    setLoading(true);
    APIClass.SettingsEndpoint.Users().then(res => {
      if (res?.success) {
        setUsers(res.content as IUser[]);
      }
      setLoading(false);
      setLoaded(true);
    });
  };

  const confirmDelete = (user: IUser) => {
    setOpen(true)
    setDeletedUser(user);
  }

  const removeUser = useCallback(() => {
    APIClass.SettingsEndpoint.RemoveUser(deletedUser!.id).then(res => {
      if (res?.success) {
        loadUsers()
        setDeletedUser(undefined)
        setOpen(false);
      }
    })
  }, [deletedUser])

  useEffect(() => {
    if (!loaded) {
      loadUsers();
    }
  }, [loaded]);
  return (
    <>
      <Dialog
        bOpen={open}
        title="Remove user"
        onToggle={() => setOpen(false)}
        onConfirm={() => removeUser()}
      >
        <p>Are you sure you want to remove &quot;{deletedUser?.name}&quot;?</p>
        <p>They will not be able to login into the client portal in the future.</p>
      </Dialog>
      <div className={css.userManagement}>
        <div style={{ overflowX: 'auto' }}>
          <TableVirtualized
            collection={users}
            bLoading={loading}
            minTableWidth={850}
            columns={[
              {
                dataKey: 'name',
                align: 'start',
                component: (name, row) => (
                  <div className={css.tableTitle}>
                    {name}
                    <p className={css.email}>{(row.items.find(item => item.contact_item_type_id === 1) || { value: 'N/A' }).value}</p>
                  </div>
                ),
                minWidth: 250,
              },
              {
                dataKey: 'client_portal_role',
                label: 'Access',
                component: clientPortalRole => (
                  <>
                    {clientPortalRole ? ((clientPortalRole as IClientPortalRole).name) : 'N/A'}
                    {clientPortalRole ? <Chip className={css.chip} label={(clientPortalRole as IClientPortalRole).is_admin ? 'Admin' : 'User'}/> : null}
                  </>
                ),
                minWidth: 200,
              },
              {
                dataKey: 'last_cp_action_at',
                label: 'Last login',
                component: lastLogin => <DateString value={lastLogin as string | null} fallback="N/A" format="DD.MM.YYYY HH:mm"/>,
                minWidth: 150,
              },
              {
                dataKey: 'id',
                label: ' ',
                component: (id, record) => (
                  <div className={css.center}>
                    <Link to={`/settings/management/${id}`}><Button icon="edit" size="small" color="neutral" iconColor="warning"/></Link>
                    <Button icon="delete" size="small" color="neutral" iconColor="danger" className={css.chip} onClick={() => confirmDelete(record)}/>
                  </div>
                ),
                minWidth: 50,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default UserManagement;
