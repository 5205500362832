// .core
import React from 'react'
// components
import { Button } from '../../basic/Button/Button'
import { Icon } from '../../basic/Icon/Icon'
// libraries
import cx from 'classnames'
// styles
import css from './Tabs.module.scss'
// utils
import { TIcon } from 'utils'

export interface ITabProps {
  /**
   * Whether the Tab is active - adds highlighting style
   */
  bActive?: boolean
  bDisabled?: boolean
  children?: React.ReactNode
  className?: string
  icon?: TIcon
  /**
   * Position of the icon within a `<Tab />`
   *
   * @default
   * undefined -> 'left'
   */
  iconPos?: 'left' | 'top' | 'right'
  label?: string
  onClick?(): void
}

export const Tab: React.FC<ITabProps> = ({
  bActive,
  bDisabled,
  className,
  icon,
  iconPos = 'left',
  label,
  onClick
}: ITabProps) => {
  const _onClick = () => {
    !bDisabled && onClick && onClick()
  }

  return (
    <Button.UI
      bDisabled={bDisabled}
      className={cx(css.wTab, bActive && css.active, css['i-' + iconPos], className)}
      onClick={_onClick}
    >
      {/* ICON */}
      {icon && <Icon name={icon} />}

      {/* LABEL */}
      {label && <span>{label}</span>}
    </Button.UI>
  )
}
