// core
import React from 'react'

// styles
import css from '../Chatbot.module.scss'

export const Thumbnail = (passingProps: Omit<React.HTMLProps<HTMLImageElement>, 'crossOrigin'>) => {
  return (
    <div className={css.thumbnail}>
      <a target="_blank" rel="noreferrer noopener" href={passingProps.src}>
        <img alt="thumbnail" {...passingProps} />
      </a>
    </div>
  )
}
