// i18n
import { t } from 'i18n';
// libraries
import * as Yup from 'yup';

export interface IChangePasswordForm {
  old_password: string
  password: string
  password_confirmation: string
}

//
// ==================== REQUEST VERIFICATION ====================
export class ChangePasswordForm {
  INITIAL_VALUES: IChangePasswordForm = {
    old_password: '',
    password: '',
    password_confirmation: '',
  };

  VALIDATION = Yup.object().shape<IChangePasswordForm>({
    old_password: Yup.string().required(t.VALIDATION.REQUIRED)
      .min(8, t.VALIDATION.PASSWORD_LENGTH(8)),
    password: Yup.string().required(t.VALIDATION.REQUIRED)
      .min(8, t.VALIDATION.PASSWORD_LENGTH(8)),
    password_confirmation: Yup.string().required(t.VALIDATION.REQUIRED)
      .min(8, t.VALIDATION.PASSWORD_LENGTH(8))
      .oneOf(
        [Yup.ref('password')],
        'Passwords do not match'
      ),
  });
}

export interface IProfileBasicForm {
  name: string
  linked_in: string | null
}

//
// ==================== REQUEST VERIFICATION ====================
export class ProfileBasicForm {
  INITIAL_VALUES: IProfileBasicForm = {
    name: '',
    linked_in: '',
  };

  VALIDATION = Yup.object().shape<IProfileBasicForm>({
    name: Yup.string().required(t.VALIDATION.REQUIRED),
    linked_in: Yup.string().nullable().url(),
  });
}

export interface IInviteUserForm {
  name: string
  email: string
  client_portal_role_id: string | null
}

//
// ==================== REQUEST VERIFICATION ====================
export class InviteUserForm {
  INITIAL_VALUES: IInviteUserForm = {
    name: '',
    email: '',
    client_portal_role_id: 'null',
  };

  VALIDATION = Yup.object().shape<IInviteUserForm>({
    name: Yup.string().required(t.VALIDATION.REQUIRED),
    email: Yup.string().required(t.VALIDATION.REQUIRED).email(t.VALIDATION.EMAIL),
    client_portal_role_id: Yup.string().nullable(),
  });
}
