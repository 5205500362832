// .core
import React, { useState } from 'react';
// libraries
import cx from 'classnames'
// styles
import css from './Checkbox.module.scss'
import { CheckboxField } from './CheckboxField/CheckboxField'
import { Field } from 'formik';

interface ICheckboxProps {
  bChecked?: boolean
  bDisabled?: boolean

  className?: string
  classNameInput?: string
  classNameLabel?: string

  id?: string
  label?: string | React.ReactNode
  name?: string

  formik?: boolean

  onChange?(value: boolean): void
}

/**
 * `Checkbox` variant of the default HTML input
 */
export const Checkbox = ({
  bChecked = false,
  bDisabled,
  className,
  classNameInput,
  classNameLabel,
  id,
  label,
  name,
  onChange,
  formik
}: ICheckboxProps) => {
  const [formikChecked, setFormikChecked] = useState<boolean>(false)

  const _onChange = () => {
    !formik && !bDisabled && onChange && onChange(!bChecked)
  }
  const _onChangeFormik = () => {
    if (formik && !bDisabled) {
        setFormikChecked(pov => ! pov)
    }
  }

  const _onCheckboxFieldClick = () => {
    // @ts-ignore
    document.querySelector(`label[for=${id || name}]`).click()
  }

  return (
    <div className={cx(css.wCheckbox, className)} onClick={_onChange}>
      {/* CHECKBOX INPUT */}
      {formik ? (
        <>
          <CheckboxField checked={formikChecked} className={cx(classNameInput)} onClick={_onCheckboxFieldClick} />
          <Field type="checkbox" name={name} id={id || name} style={{display: 'none'}} />
        </>
      ) : (
        <>
          <CheckboxField checked={bChecked} className={cx(classNameInput)} onClick={_onChange} />
          <input
            readOnly
            checked={bChecked}
            style={{ display: 'none' }}
            className={cx(classNameInput)}
            id={id || name}
            name={name}
            type="checkbox"
          />
        </>
      )}

      {/* LABEL */}
      {label && (
        <label className={cx(classNameLabel)} htmlFor={id || name} onClick={_onChangeFormik}>
          {label}
        </label>
      )}
    </div>
  )
}
