/** README !
 *
 * Global collection of all regexes used throughout the project
 *
 * @Rules none
 *
 */

export const gRegex: { [key: string]: RegExp } = {
  hex: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
  //   hex: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  name: /[^a-zA-Z\d.,:'-\s]/g,
  phone: /[^()\d-]/g,
}
