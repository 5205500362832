// core
import React from 'react'

// libraries
import { Link } from 'react-router-dom'

// logo
import WebswingLogo from 'assets/images/webswing basic default.svg'

//styles
import css from './Logo.module.scss'

export const Logo = () => {
  return (
    <div className={css.logo}>
      <Link to="/">
        <img src={WebswingLogo} alt="logo" />
      </Link>
    </div>
  )
}
