// core
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import APIClass from '../../../../../api';
import { IUser } from '../../../../../api/interfaces/IUser';
import { PageHeader } from '../../../../../components/complex';
import Profile from '../Profile';

const User = () => {
  const { id } = useParams();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();

  const loadUser = (id: number) => {
    APIClass.SettingsEndpoint.User(id)
      .then(res => {
        if (res?.success) {
          setUser(res.content as IUser);
        }
        setLoaded(true);
      });
  };

  const updateUser = (user: IUser, errorCallback: (errors: any) => {}) => {
    return APIClass.SettingsEndpoint.UpdateUser(user.id, user).then(res => {
      if (res?.success) {
        setUser(res.content as IUser)
      } else {
        // @ts-ignore
        errorCallback(res.content.errors)
      }

      return res
    })
  }

  useEffect(() => {
    if (!loaded) {
      loadUser(id);
    }
  }, [loaded, id]);

  if (!user) {
    return (
      <div>
        <PageHeader back="/settings/management" title="Profile ..."/>
      </div>
    );
  }

  return (
    <div>
      <PageHeader back="/settings/management" title={`Profile - ${user!.name}`}/>
      <Profile user={user} updateUser={updateUser}/>
    </div>
  );

};


export default User
