// core
import React from 'react'

// libraries
import classnames from 'classnames'
import { Link } from 'react-router-dom'

// styles
import css from '../Downloads.module.scss'

// components
import { Icon } from 'components'

export interface IFolderCellProps {
  isFile: boolean
  isPreview: boolean
  name: string
  to: string
}

export const DownloadCell = ({ name, isFile, isPreview, to }: IFolderCellProps) => {
  return (
    <div className={classnames(css.fileName, css.folderCell, isPreview ? css.preview : null)}>
      <Icon name={isPreview ? 'folder_open' : (isFile ? 'insert_drive_file' : 'folder')} color={isPreview ? 'neutral' : (isFile ? 'neutral' : 'blue')} />
      {isPreview ? (
        <span>{name}</span>
      ) : (
        isFile ? <a href={to} target="_blank" rel="noopener noreferrer">{name}</a> : (<Link to={`/downloads/${to}`}>{name}</Link>)
      )}
    </div>
  )
}
