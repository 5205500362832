// .core
import React from 'react'
// components
import { Icon } from '../../../Icon/Icon'
// libraries
import cx from 'classnames'
import { FlexDirectionProperty } from 'csstype'
// import { Ink } from 'react-ink'
// styles
import css from './FAB.module.scss'
// utils
import { gColors, TIcon } from 'utils'

interface IFABBasicProps {
  bDisabled?: boolean
  //   bDisableRipple?: boolean
  className?: string
  color?: typeof gColors //      #CHECK
  //   direction?: 'row' | 'row-reverse' | 'columm' | 'column-reverse'
  //   position?: 'topRight' | 'bottomRight' | 'bottomLeft' | 'topLeft'
  icon: TIcon
  style?: React.CSSProperties
  subButtons?: IFABSubButtonProps[]
  onClick?(): void
}
/**
 * To prevent declaring `subButtons` for `subButtons`
 */
interface IFABSubButtonProps extends Omit<IFABBasicProps, 'subButtons'> {}

/**
 * FAB is position in top right, buttons should extend to the left (`row-reverse`), and down (`column`)
 */
interface ITR extends IFABBasicProps {
  position: 'topRight'
  direction: 'columm' | 'row-reverse'
}
/**
 * FAB is position in bottom right, buttons should extend to the left (`row-reverse`), and up (`column-reverse`)
 */
interface IBR extends IFABBasicProps {
  position: 'bottomRight'
  direction: 'column-reverse' | 'row-reverse'
}
/**
 * FAB is positioned in bottom left, buttons should extend to the right (`row`), and up (`column-reverse`)
 */
interface IBL extends IFABBasicProps {
  position: 'bottomLeft'
  direction: 'column-reverse' | 'row'
}
/**
 * FAB is positioned in top left, buttons should extend to the right (`row`), and down (`column`)
 */
interface ITL extends IFABBasicProps {
  position: 'topLeft'
  direction: 'columm' | 'row'
}

/** Merged `FABBasicProps` w/ all direction-position variantions */
type IFABProps = ITR | IBR | IBL | ITL

/**
 * An individual or a collection of FAB buttons
 */
export const FAB: React.FC<IFABProps> = ({
  className,
  direction = 'row-reverse',
  position = 'bottomRight',
  subButtons,
  onClick,
  ...mainButtonProps
}: IFABProps) => {
  const [bActive, toggleSubButtons] = React.useState<boolean | undefined>(undefined)

  const _onClick = () => {
    if (mainButtonProps.bDisabled) return
    toggleSubButtons(!bActive)
    onClick?.()
  }

  return (
    <div
      className={cx(css.wFAB, css[position], className)}
      style={{ flexDirection: direction as FlexDirectionProperty }}>
      {/* MAIN FAB */}
      <FABBasic {...mainButtonProps} className={css.bMain} onClick={_onClick} />

      {/* SUB-BUTTONS */}
      {subButtons?.map((i, ii) => (
        <FABBasic
          {...i}
          key={ii}
          className={cx(css.bSub, bActive && css.bSubShow, bActive === false && css.bSubHide)}
          style={{ animationDelay: `${50 * ii * (bActive ? 1 : -1)}ms` }} // 1 / -1 is for reverse animation effect
        />
      ))}
    </div>
  )
}

/**
 * Basic version of a FAB
 */
const FABBasic: React.FC<IFABBasicProps> = ({
  bDisabled,
  className,
  icon,
  style,
  onClick,
}: IFABSubButtonProps) => {
  const _onClick = () => {
    !bDisabled && onClick && onClick()
  }

  return (
    <div
      className={cx(css.FAB, bDisabled && css.bDisabled, className)}
      style={style}
      onClick={_onClick}>
      <Icon name={icon} />
      {/* {!bDisableRipple && <Ink />} */}
    </div>
  )
}
