// core
import React, { useEffect, useRef, useState } from 'react'

// libraries
import classnames from 'classnames'

// styles
import css from '../Chatbot.module.scss'
import { Button, Icon, Chip, Input } from 'components/basic'
import { AuthService } from 'services/AuthService'
import { MessageGroup } from './MessageGroup'
import APIClass from 'api'
import { FormikProps, FormikValues, Formik, Form } from 'formik'
import { OwenForm, IOwenForm } from 'utils/forms/OwenForms'

export interface IPost {
  goto: string | null
  key: string
  person: string
  answer?: string | string[]
  content: string | any[]
  typing?: boolean
}

const groupByUser = (posts: IPost[]) => {
  const groupedPosts = posts.reduce((grouped: any[], post) => {
    if (grouped.length === 0) grouped.unshift([post])
    else if (grouped[0][0].person === post.person) grouped[0].push(post)
    else grouped.unshift([post])
    return grouped
  }, [])
  return groupedPosts.reverse()
}

interface IChatProps {
  handleClose: (stop?: boolean) => void
}

export const Chat = ({ handleClose }: IChatProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [opened, setOpened] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<any>(null)
  const [chatArray, setChatArray] = useState<IPost[]>([])
  const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState<string[]>([])

  const openSelect = () => {
    setOpened(prev => !prev)
  }

  const handleSetChatArray = (object: IPost) => {
    setChatArray(prev => {
      const newArray = [...prev]
      newArray.push(object)
      return newArray
    })
  }

  const { currentUser } = AuthService

  const newScriptRenderrer = (newScript?: IPost[]) => {
    const script = newScript || currentUser?.owen.script
    if (script) {
      let lastItem: { person: string; key: string } | null = currentUser?.owen.script[0]
      let currentGoto: string | null = lastItem?.key || null

      const oneGroup = []

      while (currentGoto !== null) {
        const item = script.find(({ key }) => key === currentGoto) || script[0]
        if (!(item.person.toLowerCase() !== lastItem?.person.toLowerCase() && lastItem)) {
          lastItem = item
        }

        const answeredItem =
          item.answer && typeof item.answer !== 'string'
            ? item
            : typeof item.content !== 'string' &&
              item.content.find(
                ({ content }: { content: string | undefined }) => content && content === item.answer
              )

        const inputAnswer =
          item.answer &&
          typeof item.content !== 'string' &&
          !item.content.find(
            ({ content }: { content: string | undefined }) => content && content === item.answer
          )
            ? item.content.find(
                ({ type }: { type: string }) => type === 'input' || type === 'textarea'
              )
            : null

        if (item.person === 'user' && item !== currentStep && !answeredItem) {
          item.goto = null
          setCurrentStep(item)
        } else if (answeredItem?.answer && typeof answeredItem.answer !== 'string') {
          const doNotKnow = answeredItem.content.find((item: any) => item.do_not_know)
          if (doNotKnow) {
            const check = answeredItem.answer.includes(doNotKnow.content)
              ? doNotKnow.sole_check
              : doNotKnow.non_sole_check

            oneGroup.push(item)
            oneGroup.push({ ...check, person: 'owen' })
            currentGoto = check.goto
          } else {
            currentGoto = answeredItem.goto
            oneGroup.push(item)
          }
        } else oneGroup.push(item)

        if (answeredItem) {
          if (typeof answeredItem.content === 'string') {
            currentGoto = answeredItem.goto
          }
        } else currentGoto = item.goto

        if (inputAnswer?.goto) {
          oneGroup.push(item)
          currentGoto = inputAnswer.goto
        }
      }

      if (oneGroup.length) {
        setChatArray(oneGroup)
      }
    }
  }
  useEffect(() => {
    newScriptRenderrer()
  }, [])

  useEffect(() => {
    wrapperRef.current?.scroll({ top: 2000, behavior: 'smooth' })
  }, [wrapperRef.current, chatArray, currentStep])

  async function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  async function addNextMessages(item: IPost, key: string) {
    let goto: string | null | undefined = item.goto
    if (currentStep.type === 'multiplechoice') {
      handleSetChatArray({ ...item, answer: multipleChoiceAnswers, key, person: 'user' })

      const doNotKnow = currentStep.content.find((item: any) => item.do_not_know)
      if (doNotKnow) {
        const check = multipleChoiceAnswers.includes(doNotKnow.content)
          ? doNotKnow.sole_check
          : doNotKnow.non_sole_check
        handleSetChatArray({ ...check, person: 'owen' })

        goto = check.goto
      }

      setMultipleChoiceAnswers([])
    } else handleSetChatArray({ ...item, key, person: 'user' })
    setCurrentStep(null)

    while (goto) {
      const nextItem = currentUser?.owen.script.find(({ key }) => key === goto)
      const nextItemUser = nextItem.person === 'user'
      goto = nextItemUser ? null : nextItem?.goto

      if (nextItem?.wait && !nextItemUser) {
        handleSetChatArray({ typing: true, key: 'typo', content: [], goto: null, person: 'owen' })

        await timeout((1000 * nextItem.wait) as number)

        setChatArray(prev => {
          const newArray = [...prev]
          newArray.splice(-1, 1)
          newArray.push(nextItem)
          return newArray
        })
      }

      if (nextItemUser && nextItem !== currentStep) {
        setCurrentStep(nextItem)
      }
    }
  }

  const handleMultipleChoice = (item: string) => {
    const newArray = [...multipleChoiceAnswers]
    if (multipleChoiceAnswers.includes(item))
      newArray.splice(
        multipleChoiceAnswers.findIndex(answer => answer === item),
        1
      )
    else newArray.push(item)

    setMultipleChoiceAnswers(newArray)
  }

  const optionsWithInput =
    currentStep?.type === 'options' &&
    !!currentStep.content.find(
      ({ type }: { type: string }) => type === 'input' || type === 'textarea'
    )

  const optionsWithoutInput =
    currentStep?.type === 'options' &&
    !currentStep.content.find(
      ({ type }: { type: string }) => type === 'input' || type === 'textarea'
    )
  const multipleChoice = currentStep?.type === 'multiplechoice'

  const handleSubmit = (values?: IOwenForm) => {
    setDisabled(true)
    APIClass.OwenEndpoint.Answer(currentStep.key, values?.input || [...multipleChoiceAnswers]).then(
      res => {
        setDisabled(false)
        if (res?.success) {
          setOpened(false)
          addNextMessages(
            values?.input
              ? {
                  ...currentStep.content.find(
                    ({ type }: { type: string }) => type === 'input' || type === 'textarea'
                  ),
                  content: values.input,
                }
              : currentStep,
            currentStep.key
          )
        }
      }
    )
  }

  const hideOptionsText = optionsWithInput && window.innerWidth < 750
  const multipleChoiceSubmit = multipleChoice && multipleChoiceAnswers.length > 0 && opened

  return (
    <div className={css.chatWrap}>
      <div ref={wrapperRef} className={classnames(css.chat, { [css.openedBlur]: opened })}>
        {groupByUser(chatArray).map((group, index) => (
          <MessageGroup key={'i_' + index} group={group} />
        ))}
      </div>

      <div className={classnames(css.optionsButton, { [css.select]: opened })}>
        {!opened && optionsWithInput && <h5>Your response</h5>}
        {!opened && multipleChoice && <h5 className={css.right}>Select options</h5>}
        {opened && (
          <div
            className={classnames(css.options, {
              [css.textareaOptions]: currentStep?.content?.find(
                ({ type }: { type: string }) => type === 'textarea'
              ),
            })}>
            <h5>Select option</h5>

            <div className={css.optionsChipWrap}>
              {currentStep.content
                .filter(({ type }: { type: string }) => !(type === 'input' || type === 'textarea'))
                .map((item: IPost) => {
                  const sendAnswer = () => {
                    if (multipleChoice) handleMultipleChoice(item.content as string)
                    else {
                      setDisabled(true)
                      APIClass.OwenEndpoint.Answer(currentStep.key, item.content).then(res => {
                        setDisabled(false)
                        if (res?.success) {
                          setOpened(false)
                          addNextMessages(item, currentStep.key)
                        }
                      })
                    }
                  }

                  const disabledChip =
                    disabled ||
                    (multipleChoice &&
                      ((multipleChoiceAnswers.includes("I still don't know") &&
                        !(item as any)?.do_not_know) ||
                        (multipleChoiceAnswers.length &&
                          !multipleChoiceAnswers.includes("I still don't know") &&
                          (item as any)?.do_not_know)))
                  return (
                    <Chip
                      key={item.content as string}
                      className={classnames(css.optionsChip, {
                        [css.disableHover]: disabledChip,
                        [css.selected]:
                          !multipleChoice || multipleChoiceAnswers.includes(item.content as string),
                      })}
                      type="outline"
                      bDisabled={disabledChip}
                      color="white"
                      label={item.content as string}
                      onClick={sendAnswer}
                    />
                  )
                })}
            </div>
          </div>
        )}

        {optionsWithoutInput ? (
          <>
            <h5 className={css.right}>Select option</h5>

            <div className={css.optionsChipWrap}>
              {currentStep.content.map((item: IPost) => {
                const sendAnswer = () => {
                  setDisabled(true)
                  APIClass.OwenEndpoint.Answer(currentStep.key, item.content).then(res => {
                    setDisabled(false)
                    if (res?.success) {
                      addNextMessages(item, currentStep.key)
                      if (currentStep.key === 'finish') {
                        handleClose(true)
                      }
                    }
                  })
                }
                return (
                  <Chip
                    key={item.content as string}
                    className={classnames(css.optionsChip, css.selected)}
                    type="outline"
                    color="white"
                    bDisabled={disabled}
                    label={item.content as string}
                    onClick={sendAnswer}
                  />
                )
              })}
            </div>
          </>
        ) : null}

        {optionsWithInput || multipleChoice ? (
          <div
            className={classnames(css.buttonWrap, {
              [css.float]: multipleChoice,
              [css.hideOptionsText]: hideOptionsText,
            })}>
            {optionsWithInput ? (
              <Formik
                initialValues={OwenForm.INITIAL_VALUES}
                validationSchema={OwenForm.VALIDATION}
                onSubmit={handleSubmit}>
                {(form: FormikProps<FormikValues>) => (
                  <Form autoComplete={Math.random().toString()} className={css.form}>
                    <Input.Field
                      bAutoFocus
                      className={css.responseInput}
                      placeholder="Add your response"
                      autoComplete="off"
                      name="input"
                      bTextArea={
                        !!currentStep.content.find(
                          ({ type }: { type: string }) => type === 'textarea'
                        )
                      }
                      onKeyDown={e => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault()
                          form.submitForm()
                        }
                      }}
                    />
                    <Button.UI
                      className={css.sendButton}
                      size="small"
                      bDisabled={!(form.isValid && form.touched) || disabled}
                      onClick={form.submitForm}>
                      <Icon name="send" color="blue" size="small" />
                    </Button.UI>
                  </Form>
                )}
              </Formik>
            ) : null}

            {multipleChoice ||
            currentStep.content.filter(({ type }: { type: string }) => type === 'button').length ? (
              <Button.UI
                size="small"
                bDisabled={multipleChoiceSubmit && disabled}
                onClick={multipleChoiceSubmit ? () => handleSubmit() : openSelect}>
                {multipleChoiceSubmit
                  ? 'Submit'
                  : !hideOptionsText && (opened ? 'Close' : 'Options')}{' '}
                <Icon
                  className={classnames(css.optionsIcon, { [css.opened]: opened })}
                  name={multipleChoiceSubmit ? 'check_circle' : hideOptionsText ? 'add' : 'cancel'}
                  color="black"
                  size={hideOptionsText ? 'small' : 'mini'}
                />
              </Button.UI>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}
