// .core
import React from 'react'
// components
import { Spinner } from '../Spinner/Spinner'
// libraries
import cx from 'classnames'
// styles
import css from './Fullscreen.module.scss'

interface IFullScreenProps {
  children?: React.ReactNode
  className?: string
}

export const Fullscreen = ({ children, className }: IFullScreenProps) => (
  <div className={cx(css.wFullscreen, className)}>{children ?? <Spinner />}</div>
)
