export const cookieOptions = {
    autorun: true,
    current_lang: 'en',
    autoclear_cookies: true,
    page_scripts: true,
    //force_consent: true,
    gui_options: {
        consent_modal: {
            layout: 'cloud',               // box/cloud/bar
            position: 'bottom center',     // bottom/middle/top + left/right/center
            transition: 'zoom',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            // position: 'left',           // left/right
            transition: 'slide'            // zoom/slide
        }
    },
    languages: {

    'en': {
        consent_modal: {
        title: 'Consent to the use of cookies',
        description: `<Here>When you click “Accept”, you allow us to collect fundamental analytics that help us improve our site in order to provide you with better content and services. Read our <a href='/privacy-policy' target={'_blank'}>Privacy Policy</a>. Here you can manage cookie <button type="button" aria-label="View cookie settings" data-cc="c-settings" class="cc-link"> settings</button>.</p>`,

        primary_btn: {
            text: 'Accept',
            role: 'accept_all'              // 'accept_selected' or 'accept_all'
        },
        // secondary_btn: {
        //     text: 'Manage Settings',
        //     role: 'settings'        // 'settings' or 'accept_necessary'
        // }
        },
        settings_modal: {
        title: 'Cookie preferences',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept',
        close_btn_label: 'Close',
        cookie_table_headers: [
            {col1: 'Name'},
            {col2: 'Description'}
        ],
        blocks: [
            {
            title: 'Cookies usage',
            description: 'By accepting cookies you allow us to collect analytic data that help us improve our site in order to provide you with better content and services.'
            }, {
            title: 'Functional cookies',
            description: 'These cookies are necessary for the functionality of the website and do not store any personal data.',
            toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
            },
            }, {
                title: 'Analytics cookies',
                description: 'These cookies collect anonymous information and provide a way for us to improve the functioning of the website and help improve its usage.',
                toggle: {
                    value: 'analytics',
                    enabled: true,
                    readonly: false          // cookie categories with readonly=true are all treated as "necessary cookies"
                },
            }, {
            title: 'Marketing cookies',
            description: 'These cookies provide us with deeper analytics and allow us to combine various data to reach out to you with more information about Webswing beyond our website.',
            toggle: {
                value: 'marketing',     // your cookie category
                enabled: true,
                readonly: false
            },
            },
        ]
        }
    },
    },
}

