// API
import { CallAPI } from '../APIUtils';
import { IRequestVerificationForm } from '../../utils/forms/DashboardForms';

export class DashboardEndpoint {
  async Statistics() {
    return CallAPI({ method: 'GET', URL: `/dashboard/stats`, bAuthentificate: true });
  }

  async LatestNews() {
    return CallAPI({ method: 'GET', URL: `/dashboard/latest-news`, bAuthentificate: true });
  }

  async LatestNewsTrack(body: { b: number }) {
    return CallAPI({ method: 'PUT', URL: `/dashboard/latest-news/t`, bAuthentificate: true, body });
  }

  async RequestVerification(body: IRequestVerificationForm) {
    return CallAPI({ method: 'POST', URL: `/dashboard/verify-me`, bAuthentificate: true, body });
  }
}
