// .core
import React from 'react'
// components
import { ISkeletonProps } from './SkeletonUI'
// libraries
import cx from 'classnames'
// styles
import css from '../Skeleton.module.scss'

interface ISkeletonSquareProps extends ISkeletonProps {}

// #TODO
export const SkeletonSquare: React.FC<ISkeletonSquareProps> = ({
  className
}: ISkeletonSquareProps) => <div className={cx(css.wSkeletonSquare, className)} />
